<template>
    <div v-bind:class="[!showComponent ? 'hide-components' : '']" 
                class="section-services-components">
        <div class="wrapper ">
            <h2 class="main-title">Components</h2>
            <span class="example-case">(This is an example. It will be customized for your case)</span>
            <div class="wrapper-swipper" 
                 v-for="service in getPageServices"
                 :key="service.url">
                <swiper :options="swiperOption" :class="service.url">
                    <swiper-slide v-for="component_services in service.component_carousel"
                                    :key="component_services.main_label">
                        <h2 class="component-main_label">{{component_services.main_label}}</h2>
                        <p class="swipper-description">
                            {{component_services.description}}
                        </p>
                        <section class="wrap-delivery">
                            <h2 class="caption-delivery">{{component_services.deliver}}</h2>
                            <ul class="list-delivery">
                                <li v-for="component_images in component_services.component_images"
                                    :key="component_images.image">
                                    <picture>
                                        <source :srcset="component_images.image_retina" media="(max-width: 415px)" />
                                        <img :src="component_images.image" alt="">
                                    </picture>
                                    <span>{{component_images.label}}</span>
                                </li>
                            </ul>
                        </section>
                    </swiper-slide>
                    <ul class="swiper-pagination numbers" slot="pagination"></ul>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    import 'swiper/dist/css/swiper.css'
    let vm = {};
    export default {
        name: 'services-component',
        components: {
            swiper,
            swiperSlide,
        },
        data() {
            return {
                serviceUrl: this.$route.params.Surl,

                showComponent: true,

                swiperOption: {
                    direction: 'vertical',
                    paginationClickable: true,
                    slidesPerView: 1,
                    spaceBetween: 1,
                    freeMode: true,
                    freeModeSticky: true,
                    mousewheel: {
                        invert: false,
                        releaseOnEdges: true,
                    },
                    touchReleaseOnEdges: true,
                    speed: 1000,
                    pagination: {
                    el: '.swiper-pagination',
                        clickable: true,
                        renderBullet: function (index, className) {
                            return `<li class="${className}">
                                        <span class="bullets-text">${ vm.getComponentCarousel[index].main_label}</span>
                                        <div class="vertical-line"></div>
                                    </li>`
                        }
                    }
                },
                services_component: [
                    {
                        url: 'user-research',
                        component_carousel: [
                            {
                                main_label: 'Analysis',
                                description: 'We analyze your product or idea as well as competitors from the perspective of User Experience and business KPIs. Besides the qualitative data that we get from the analytics, we collect quantitative insights by observing the users using the product. Based on the data collected we make suggestions for improvement.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/User_research/List_of_suggestions.svg',
                                        image_retina: '/img/images/page-services/User_research/List_of_suggestions@2x.png',
                                        label: 'List of suggestions',
                                    }
                                ],
                            },
                            {
                                main_label: 'User interview',
                                description: 'This quantitative method of research works for both existing products and ideas. The purpose of the user interview is to learn how users solve their pain points your products supposed to help with. We run users interviews face-to-face either offline or online. User interviews give very realistic and workable results, that help to create a better product.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/User_research/Recording.svg',
                                        image_retina: '/img/images/page-services/User_research/Recording@2x.png',
                                        label: 'Recording',
                                    },
                                    {
                                        image: '/img/images/page-services/User_research/Insights.svg',
                                        image_retina: '/img/images/page-services/User_research/Insights@2x.png',
                                        label: 'Insights',
                                    }
                                ],
                            },
                            {
                                main_label: 'Personas',
                                description: 'User personas are the verbal portraits of the typical target users and their behavior, and they are helpful as a reference point during the whole design process. During the session with the stakeholders, we normally create personas at the beginning of working on the product.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/User_research/Persona_doc.svg',
                                        image_retina: '/img/images/page-services/User_research/Persona_doc@2x.png',
                                        label: 'Persona doc',
                                    }
                                ],
                            },
                            {
                                main_label: 'User testing',
                                description: 'We use user testing a lot to test our interfaces. It is an extremely useful method that helps to significantly improve the product by testing the hypothesis and new features. Testing prototypes instead of already implemented features or products dramatically reduce the development cost.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/User_research/Recording.svg',
                                        image_retina: '/img/images/page-services/User_research/Recording@2x.png',
                                        label: 'Recording',
                                    },
                                    {
                                        image: '/img/images/page-services/User_research/Insights.svg',
                                        image_retina: '/img/images/page-services/User_research/Insights@2x.png',
                                        label: 'Insights',
                                    }
                                ],
                            }
                        ],
                    },
                    {
                        url: 'design-audit', 
                        component_carousel: [
                            {
                                main_label: 'Comprehensive analysis',
                                description: 'We analyze the UI/UX patterns used in the product in combination with the current usage data and knowledge about the target user’s behavioral patterns.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Design_audit/Insights.svg',
                                        image_retina: '/img/images/page-services/Design_audit/Insights@2x.png',
                                        label: 'Insights',
                                    }
                                ],
                            },
                            {
                                main_label: 'Testing',
                                description: 'Testing We analyze the target user’s behavior while they use the product and detect the issues.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Design_audit/Insights.svg',
                                        image_retina: '/img/images/page-services/Design_audit/Insights@2x.png',
                                        label: 'Insights',
                                    },
                                    {
                                        image: '/img/images/page-services/Design_audit/Recording.svg',
                                        image_retina: '/img/images/page-services/Design_audit/Recording@2x.png',
                                        label: 'Recording, Insights',
                                    },
                                ],
                            }
                        ],
                    },
                    {
                        url: 'app-prototype',
                        component_carousel: [
                            {
                                main_label: 'Research',
                                description: 'We execute the problem, focus on and prioritize user’s needs.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Apps_prototyping/User_story.svg',
                                        image_retina: '/img/images/page-services/Apps_prototyping/User_story@2x.png',
                                        label: 'User story',
                                    },
                                    {
                                        image: '/img/images/page-services/Apps_prototyping/Report.svg',
                                        image_retina: '/img/images/page-services/Apps_prototyping/Report@2x.png',
                                        label: 'Report',
                                    }
                                ],
                            },
                            {
                                main_label: 'Ideation',
                                description: 'This is the time when we generate and evaluate the solutions to the user’s and business’ problems.',
                                deliver: '',
                            },
                            {
                                main_label: 'Wireframes',
                                description: 'We create a low-fidelity prototype of a future product or feature that can be already tested on the early stages.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Apps_prototyping/Lo-fi_prototype.svg',
                                        image_retina: '/img/images/page-services/Apps_prototyping/Lo-fi_prototype@2x.png',
                                        label: 'Lo-fi prototype',
                                    }
                                ],
                            },
                            {
                                main_label: 'Mockups',
                                description: 'Here we create high-fidelity screens with polished UI elements.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Apps_prototyping/High-fi_prototype.svg',
                                        image_retina: '/img/images/page-services/Apps_prototyping/High-fi_prototype@2x.png',
                                        label: 'High-fi prototype',
                                    }
                                ]
                            },
                            {
                                main_label: 'Design system',
                                description: 'We design an organized, scalable and sustainable library of UI elements and interactions that help keep the project organized and save an unbelievable amount of production money.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Apps_prototyping/Design_library.svg',
                                        image_retina: '/img/images/page-services/Apps_prototyping/Design_library@2x.png',
                                        label: 'Design library',
                                    },
                                    {
                                        image: '/img/images/page-services/Apps_prototyping/Design_document.svg',
                                        image_retina: '/img/images/page-services/Apps_prototyping/Design_document@2x.png',
                                        label: 'Design document',
                                    }
                                ]
                            },
                        ],
                    },
                    {
                        url: 'present-design',
                        component_carousel: [
                            {
                                main_label: 'Brief',
                                description: 'We agree with the information that will be displayed on the slides.',
                                deliver: '',
                            },
                            {
                                main_label: 'Presentation design',
                                description: 'We place and present the information in the most clear and impactful way.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Presentation_Marketing_design/Presentation.svg',
                                        image_retina: '/img/images/page-services/Presentation_Marketing_design/Presentation@2x.png',
                                        label: 'Presentation',
                                    }
                                ],
                            },
                            {
                                main_label: 'Social media materials',
                                description: 'We create attractive visual materials for your marketing campaigns.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Presentation_Marketing_design/Graphics.svg',
                                        image_retina: '/img/images/page-services/Presentation_Marketing_design/Graphics@2x.png',
                                        label: 'Graphics',
                                    }
                                ],
                            },
                            {
                                main_label: 'Branding and Promo design',
                                description: 'We can design branding and promo materials for your business.',
                                deliver: '',
                            }
                        ],
                    },
                    {
                        url: 'product-page',
                        component_carousel: [
                            {
                                main_label: 'Research',
                                description: 'We learn about the product and its competitors and find the best way to make the product stand out.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Product_page_design/Moodboard.svg',
                                        image_retina: '/img/images/page-services/Product_page_design/Moodboard@2x.png',
                                        label: 'Moodboard',
                                    }
                                ],
                            },
                            {
                                main_label: 'Wireframes',
                                description: 'The low-fidelity prototype of the landing page indicates how we deliver the product to users.',
                                deliver: 'Deliverables',                                
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Product_page_design/Lo-fi_prototype.svg',
                                        image_retina: '/img/images/page-services/Product_page_design/Lo-fi_prototype@2x.png',
                                        label: 'Lo-fi prototype',
                                    }
                                ],
                            },
                            {
                                main_label: 'Mockups',
                                description: 'Here we create high-fidelity screens with polished UI elements.',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Product_page_design/High-fi_prototype.svg',
                                        image_retina: '/img/images/page-services/Product_page_design/High-fi_prototype@2x.png',
                                        label: 'High-fi prototype',
                                    }
                                ],
                            }
                        ],
                    },
                    {
                        url: 'development',
                        component_carousel: [
                            {
                                main_label: 'Project management',
                                description: 'We can manage both designers and developers and coordinate their work in the most efficient and organized manner so you don’t need to worry about anything.',
                                deliver: '',
                            },
                            {
                                main_label: 'Testing',
                                description: 'We do enjoy to see our design being implemented in the right way. So we provide functional and visual testing of the products we develop.',
                                deliver: '',
                            }
                        ],
                    },
                    {
                        url: 'design-support',
                    },
                    {
                        url: 'illustration',
                        component_carousel: [
                            {
                                main_label: 'Research',
                                description: 'Finding the examples and define the style of future illustrations (flat, isometric).',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Corporate_2D_3D_Illustrations/Moodboard.svg',
                                        image_retina: '/img/images/page-services/Corporate_2D_3D_Illustrations/Moodboard@2x.png',
                                        label: 'Moodboard',
                                    }
                                ],
                            },
                            {
                                main_label: 'Hand-drawn sketches',
                                description: 'We create hand-drawn conceptual sketches for the future illustrations',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Corporate_2D_3D_Illustrations/Sketches.svg',
                                        image_retina: '/img/images/page-services/Corporate_2D_3D_Illustrations/Sketches@2x.png',
                                        label: 'Sketches',
                                    }
                                ],
                            },
                            {
                                main_label: 'Creation of illustration',
                                description: 'Finalizing the illustrations using 2D or 3D graphical tools.',
                                deliver: 'Deliverables',
                                component_images: [
                                    {
                                        image: '/img/images/page-services/Corporate_2D_3D_Illustrations/Ready_illustrations.svg',
                                        image_retina: '/img/images/page-services/Corporate_2D_3D_Illustrations/Ready_illustrations@2x.png',
                                        label: 'Ready illustrations',
                                    }
                                ],
                            }
                        ],
                    },
                ]
            }
        },
        beforeCreate(){
            vm = this;
        },
        mounted: () => {
            vm.emptyComponent();
        },
        methods: {
            emptyComponent: () => {
                if(vm.getPageServices[0].component_carousel === undefined){
                    return vm.showComponent = !vm.showComponent;
                    // console.log()
                }
            }
        },
        computed: {
            getPageServices: () => vm.services_component.filter (item => vm.serviceUrl === item.url),
            getComponentCarousel: () => vm.getPageServices[0].component_carousel,
        }
    }
</script>

<style>
.hide-components{
    display: none;
}
.wrapper-swipper{
    /* position: absolute;
    top: 0; */
    padding-bottom: 100px;
}
.swiper-container.swiper-container-initialized.swiper-container-vertical{
   height: 500px;
   overflow: hidden; 
}
.numbers{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px;
}

.numbers li {
    position: relative;
    display: flex !important;
    flex-direction: column;
    align-items: center;
}
.numbers li.swiper-pagination-bullet::before{
    content: '';
    width: 21px;
    height: 21px;
    background: url('/img/icons/icon_circle.svg');
    background-size: 100%;
    opacity: 1;
    transition: .3s all ease-in-out;
}
.numbers li.swiper-pagination-bullet-active .bullets-text{
    font-size: 20px;
}

.numbers li.swiper-pagination-bullet.swiper-pagination-bullet-active::before{
    content: '';
    transform: scale(1.3);
}
.numbers li.swiper-pagination-bullet-active .vertical-line{
    height: 284px;
}
.numbers li:last-child.swiper-pagination-bullet-active .vertical-line{
    height: 0;
}
.swiper-pagination-bullet{
    height: 100%;
}
.swiper-pagination-bullet-active{
    background: transparent !important;
}
/* .numbers li:last-child a.swiper-pagination-bullet-active + span:last-child{
    font-size: 28px;
} */
.numbers li .vertical-line{
    position: relative;
    top: 6px;
    display: inline-block;
    height: 0;
    width: 1px;
    background: #9C9EDE;
    transition: height 300ms ease-out;
}
.numbers li .bullets-text{
    position: absolute;
    left: 35px;
    top: 0;
    text-align: left;
    width: max-content;
    background: transparent;
    height: 100%;
    font-size: 16px;	
    font-weight: 500;	
    letter-spacing: 0.15px;	
    line-height: 19px;
}
.swiper-container-vertical > .swiper-pagination-bullets{
    /* top: 34%; */
    right: auto;
}
.swiper-container {
    color: #fff;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}
.swiper-slide {
    left: 140px;
    position: relative;
    width: 100%;
    max-width: 671px;
    margin: 0 auto;
    top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
    background: #0F0F24;
}
.swiper-pagination-bullet{
    opacity: 1;
}
@media screen and (max-width: 1024px){
    .swiper-slide {
        max-width: 400px;
    }
}
@media screen and (max-width: 768px){
    .swiper-slide {
        left: 55px;
        top: 25px;
        max-width: 100%;
    }  
    .swiper-wrapper .swiper-slide{
        top: 40px;
    }
    .wrapper-swipper .design-audit .swiper-slide{
        top: 70px;
    }
    .wrapper-swipper .app-prototype .swiper-slide{
        top: 25px;
    }
    .wrapper-swipper .product-page .swiper-slide{
        top: 55px;
    }
    .wrapper-swipper .development .swiper-slide{
        top: 70px;
    }
    .wrapper-swipper .illustration .swiper-slide{
        top: 55px;
    }
    .bullets-text{
        display: none;
    }
    .swiper-container{
        padding-top: 10px;
    }
}

</style>
