<template>
    <main>
        <div class="current-portfolio">
            <picture>
                <source srcset="/img/images/portfolio/Alacris/Mobile/Alacris_header.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/Alacris/Mobile/Alacris_header-iphone.png" media="(max-width: 414px)" />
                <source srcset="/img/images/portfolio/Alacris/Alacris_header.png" media="(max-width: 1699px)" />
                <source srcset="/img/images/portfolio/Alacris/Alacris_header-full-hd.png" media="(min-width: 1700px)" />

                <img class="main-img_portfolio main-img_alacris"
                     src="/img/images/portfolio/Alacris/Alacris_header-full-hd.png" alt="">
            </picture>
            <section class="wrapper current-portfolio current-portfolio_alacris">
                <picture>
                    <source srcset="/img/images/portfolio/Alacris/Mobile/bg_1.png" media="(max-width: 500px)" />
                    <img class="bg-alacris_1" src="/img/images/portfolio/Alacris/bg_1.png" alt="">
                </picture>
                <h1 class="current-portfolio_title">Alacris</h1>
                <ul class="portfolio-list_tags">
                    <li>#Design Support</li>
                    <li>#Demo Prototype</li>
                    <li>#Fintech</li>
                    <li>#Blockchain</li>
                    <li>#Landing Page</li>
                </ul>
                <h2 class="current-portfolio_subtitle">
                    <em>Alacris </em>developers the technology that speeds up a blockchain
                </h2>
                <p class="current-portfolio_description">
                    As a startup Alacris required all kind of design help: from branding and presentation to designing
                    the prototypes for demos of the technology they are developing.
                </p>
            </section>

            <div class="wrapper wrapper-alacris">

                <div class="portfolio-description challenge-portfolio">
                    <section>
                        <h2>Challenge</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            <a target="_blank" href="https://alacris.io/">Alacris</a>
                            is a fast-growing startup that changes its course on the flight.
                            Working with is required a quick turnaround. We had to be extremely flexible
                            and to <strong>prioritize</strong> either speed or fidelity of the product depending
                            on the particular situation.
                        </p>
                    </section>
                     <picture>
                        <source srcset="/img/images/portfolio/Alacris/Mobile/Challenge.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Alacris/Mobile/Group_6-iphone.png" media="(max-width: 414px)" />                    
                        <img class="alacris-img_challenge" src="/img/images/portfolio/Alacris/Challenge.png" alt="">
                    </picture>
                </div>

                <div class="portfolio-description portfolio-audience">
                    <section>
                        <h2>Target audience</h2>
                        <div class="horizontal-line"></div>
                        <p>Our target audience for this project were investors and potential partners in the
                            fintech domain. For the investors, the information we were giving them should
                            highlight the benefits and some details of the technology, but not to overwhelm them
                            with technical details, that could be quite complex when it comes to blockchain.
                            For partners, on the contrary, we could come up with all the details but we had to
                            deliver good navigation through them.
                        </p>
                    </section>
                    <picture>
                        <source srcset="/img/images/portfolio/Alacris/Mobile/target_audience.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Alacris/Mobile/Bitmap_Copy-iphone.png" media="(max-width: 414px)" />
                        <img class="target-audience" src="/img/images/portfolio/Alacris/target_audience.png" alt="">
                    </picture>
                </div>

                <div class="portfolio-description portfolio-things_we-did">
                    <section>
                        <h2>Things we did</h2>
                        <div class="horizontal-line"></div>
                        <p>Our history working for Alacris project is very diverse. We were delivering all kind
                            of products for them, such as <strong>branding,</strong>
                            demo web app <strong> prototypes, landing page </strong>design
                            and development, <strong> presentation and marketing materials,</strong>
                            and even a <strong> mascot.</strong>
                        </p>
                    </section>
                    <picture>
                        <source srcset="/img/images/portfolio/Alacris/Mobile/Things_we_did.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Alacris/Mobile/alacris_wallet_ui-iphone.png" media="(max-width: 414px)" />
                        <img class="img-things_did" src="/img/images/portfolio/Alacris/Things_we_did.png" alt="">
                    </picture>
                </div>
            </div>
        </div>

        <section class="more-projects alacris-more-projects">
            <h2 class="main-title">More projects</h2>
            <div class="wrapper">
                <MoreProjects :projects="moreProjects"/>
            </div>
        </section>
        <Workshop />
    </main>
</template>

<script>
    import MoreProjects from "../partials/MoreProjects";
    import Workshop from "../Home/Workshop";

    export default {
        name: 'alacris',
        components: {MoreProjects, Workshop},
        data() {
            return {
                moreProjects: [
                    {
                        image: "/img/images/portfolio/main/Ember.png",
                        image_hover: "/img/images/portfolio/main/Ember_clear.png",
                        title: "Ember Wave",
                        url: "/portfolio/ember",
                        tags: [
                            {
                                name: "#Design Support",
                            },
                            {
                                name: "#Mobile App",
                            },
                            {
                                name: "#IoT",
                            },
                            {
                                name: "#Wearables",
                            },
                            {
                                name: "#User Research"
                            },
                        ],
                    },
                    {
                        image: "/img/images/portfolio/main/Stressguide.png",
                        image_hover: "/img/images/portfolio/main/Stressguide_clear.png",
                        title: " Stress Guide",
                        url: "/portfolio/stress-guide",
                        tags: [
                            {
                                name: "#Mobile App",
                            },
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#User Research",
                            },
                            {
                                name: "#Healthcare",
                            },
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Earth_rating.png",
                        image_hover: "/img/images/portfolio/main/Earth_rating_clear.png",
                        title: "Earth Rating",
                        url: "/portfolio/rating",
                        tags: [
                            {
                                name: "#Web App Prototyping",
                            },
                            {
                                name: "#Presentation",
                            },
                            {
                                name: "#Ecommerce",
                            },
                            {
                                name: "#Environment",
                            },
                        ]
                    },
                ],
            }
        }
    }
</script>