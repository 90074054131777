<template>
    <main>
        <div class="current-portfolio">
            <picture>
                <source srcset="/img/images/portfolio/Health_Wizz/Mobile/HW_header_img_mobile.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/Health_Wizz/Mobile/HW_header_img_mobile-iphone.png" media="(max-width: 414px)" />
                <source srcset="/img/images/portfolio/Health_Wizz/header_image.png" media="(max-width: 1699px)" />
                <source srcset="/img/images/portfolio/Health_Wizz/bg_image_health_wizz-full-hd.png" media="(min-width: 1700px)" />
                <img class="main-img_portfolio main-img_health" src="/img/images/portfolio/Health_Wizz/bg_image_health_wizz-full-hd.png" alt="">
            </picture>
            <section class="wrapper current-portfolio current-portfolio_health">
                <h1 class="current-portfolio_title">Health Wizz</h1>
                <ul class="portfolio-list_tags">
                    <li>#Webapp</li>
                    <li>#Blockchain</li>
                    <li>#Healthcare</li>
                    <li>#Design System</li>
                    <li>#SaaS</li>
                    <li>#Fintech</li>
                </ul>
                <h2 class="current-portfolio_subtitle">
                    <em>Health Wizz </em>is the blockchain powered platform for the health providers and their
                    patients for aggregating, organizing and sharing medical data.
                </h2>
                <p class="current-portfolio_description">
                    We dramatically redesigned Health Wizz public-facing mobile app and designed from
                    scratch Health Wizz B2B web app, providing for them the sustainable design system
                    and design support.
                </p>
            </section>
            <div class="wrapper wrapper-health">
                <picture>
                    <source srcset="/img/images/portfolio/Health_Wizz/Mobile/Bg_img_1.png" media="(max-width: 320px)" />
                    <source srcset="/img/images/portfolio/Health_Wizz/Mobile/bg_1-iphone.png" media="(max-width: 414px)" />

                    <img class="health-img_challenge" src="/img/images/portfolio/Health_Wizz/bg_img_2.png" alt="">
                </picture>
                <picture>
                    <source srcset="/img/images/portfolio/Health_Wizz/Mobile/Bg_img_1.png" media="(max-width: 320px)" />
                    <source srcset="/img/images/portfolio/Health_Wizz/Mobile/bg_2-iphone.png" media="(max-width: 414px)" />

                    <img class="health-img_app" src="/img/images/portfolio/Health_Wizz/bg_img_3.png" alt="">
                </picture>
                <div class="portfolio-description health-chellenge_desc">
                    <section>
                        <h2>Goals and Challenges</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            <a target="_blank" href="https://www.healthwizz.com/"> Health Wizz </a>project offered us the whole set of challenges.
                        </p> <br>
                        <p>
                            First of all, we had to design two products that have totally different
                            goals and audience, yet, share the same environment and database.
                        </p> <br>
                        <p>
                            Product 1 - <strong> Mobile app for patients</strong> <br>
                            Product 2 - <strong>B2B Web app for care providers</strong>
                        </p> <br>
                        <p>
                            Another global challenge was the complexity of the domain (Healthcare)
                            and the technology (Blockchain) behind the products.
                        </p>
                    </section>
                     <picture>
                        <source srcset="/img/images/portfolio/Health_Wizz/Mobile/Goals.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Health_Wizz/Mobile/Screen_Shot-iphone.png" media="(max-width: 414px)" />

                        <img class="health-img_goals" src="/img/images/portfolio/Health_Wizz/Goals.png" alt="">
                    </picture>
                </div>
                <div class="portfolio-description health-description_design">
                    <section>
                        <h2>Web app design</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            Health Wizz is a web app which enables care providers to keep track of their
                            patient’s health. One of the main features of the app is to provide them a
                            platform to keep track of chronic disease patients. Another important part of
                            the functionality is allowing research centers to find matching donors of medical
                            data and reward them securely and anonymously.
                        </p> <br>
                        <p>
                            Some of the global challenges we faced:
                            1. Our web app is supposed to be used for big hospitals, that mostly
                            use FHIR standard, but also small doctor's offices that might not be using it.
                            We had to make the platform <strong>inclusive for all data standards.</strong>
                            2. To reward patients for health-related activity on Health Wizz platform,
                            care providers should use crypto tokens. We had to make the financial
                            <strong>transactions with cryptocurrencies maximally clear </strong>and non-distracting
                            for our users - care providers.
                        </p> <br>
                        <p>Working on Health Wizz we created the
                            <strong>scalable modular interface architecture.</strong>
                        </p>
                        <p> <br>
                            Using research and testing methods to prove our design decisions,
                            we designed the UX flow that <strong> minimized the
                            complexity of creating and using crypto wallets.</strong>
                        </p>
                    </section>
                       <picture>
                        <source srcset="/img/images/portfolio/Health_Wizz/Mobile/Web_app_1.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Health_Wizz/Mobile/Web_app_1-iphone.png" media="(max-width: 414px)" />

                        <img class="health-design-1" src="/img/images/portfolio/Health_Wizz/web_app_1.png" alt="">
                    </picture>

                     <picture>
                        <source srcset="/img/images/portfolio/Health_Wizz/Mobile/Web_app_2.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Health_Wizz/Mobile/Web_app_2-iphone.png" media="(max-width: 414px)" />

                        <img class="health-design-2" src="/img/images/portfolio/Health_Wizz/Web_app_2.png" alt="">
                    </picture>
                </div>

                <div class="portfolio-description mobile-app_description">
                    <section>
                        <h2>Mobile app design</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            Health Wizz mobile app aimed to help individuals to manage their medical records using
                            blockchain technology. We planned to simplify and secure their communication with
                            healthcare providers and give them an efficient platform that allows to take care
                            of their health and even receive monetary rewards.
                        </p> <br>
                        <p>
                            - Having in mind the behavior changing nature of the interaction in the app,
                            we introduced some gamification elements to increase the user’s engagement.
                        </p> <br>
                        <p>
                            - We designed the UX flows with cryptocurrencies so simple for users that they might
                            not even realize that the in-app tokens are the crypto ones.
                        </p> <br>
                        <p>
                            - We created a well-documented Design System that can be scaled and make the
                            developer’s life easier. We used the newest technologies and approaches to simplify
                            the project management and development process.
                        </p>
                    </section>
                    <img class="app-design_img" src="/img/images/portfolio/Health_Wizz/Mobile_app_design.png" alt="">
                </div>
            </div>
        </div>

        <section class="more-projects ember-more_projects">
            <h2 class="main-title">More projects</h2>
            <div class="wrapper">
                <MoreProjects :projects="moreProjects"/>
            </div>
        </section>
        <Workshop />

    </main>
</template>

<script>
    import MoreProjects from "../partials/MoreProjects";
    import Workshop from "../Home/Workshop";

    export default {
        name: 'alacris',
        components: {MoreProjects, Workshop},
        data() {
            return {
                moreProjects: [
                    {
                        image: "/img/images/portfolio/main/Human_capital.png",
                        image_hover: "/img/images/portfolio/main/Human_capital_clear.png",
                        title: "Human Capital",
                        url: "/portfolio/human-capital",
                        tags: [
                            {
                                name: "#Web Site",
                            },
                            {
                                name: "#UX Research",
                            },
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Recruitment",
                            },
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Infra.png",
                        image_hover: "/img/images/portfolio/main/Infra_clear.png",
                        title: "Infra",
                        url: "/portfolio/infra",
                        tags: [
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Rebranding",
                            },
                            {
                                name: "#Landing page",
                            },
                            {
                                name: "#Marketing design",
                            },
                            {
                                name: "#SaaS",
                            },
                            {
                                name: "#Cyber Security",
                            },
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Earth_rating.png",
                        image_hover: "/img/images/portfolio/main/Earth_rating_clear.png",
                        title: "Earth Rating",
                        url: "/portfolio/rating",
                        tags: [
                            {
                                name: "#Web App Prototyping",
                            },
                            {
                                name: "#Presentation",
                            },
                            {
                                name: "#Ecommerce",
                            },
                            {
                                name: "#Environment",
                            },
                        ]
                    },
                ],
            }
        }
    }
</script>