<template>
    <div class="section-freebies">
        <section class="wrapper wrap-freebies_page">
            <h2>Freebies</h2>
            <p>
                Sometimes we like to step away from the everyday routine and experiment and create something nice. Sometimes we allow ourselves to enjoy the creative process without being restricted by any business goals. After having fun from creative expressions its just natural to share the result with others.
            </p>
            <ul class="list-freebies row col-md-12 col-sm-8">
                <li v-for="freebie in list_freebies"
                    :key="freebie.url"
                    >
                    <img :src="freebie.image" alt="">
                    <section class="freebies-item_section">
                        <h3 @click="goToFreebie(freebie.url)" :to="freebie.url">{{freebie.caption}}</h3>
                        <p>{{freebie.description}}</p>
                        <a @click="goToFreebie(freebie.url)" class="item-freebie_link" :to="freebie.url">Link more and download</a>
                    </section>
                </li>
            </ul>
        </section>
    <Workshop />
    </div>
</template>

<script>
import Workshop from '../Home/Workshop'
    let vm = {};
    export default {
        name: 'freebies-page',
        components: {Workshop},
        data() {
            return {
                freebieUrl: this.$route.params.Furl,

                list_freebies: [
                    {
                        url: 'people-illustrations',
                        image: 'https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Mail_notification_freebies_Aprl_08.png',
                        caption: 'Everyday icons set',
                        description: 'Download great icons set designed specifically for blockchain projects. The set has 36 flat and outlines icons in .sketch, .png and .svg formats…'
                    },
                    {
                        url: 'city-light-boxes',
                        image: '../img/images/freebies/AD_cover_img.png',
                        caption: 'City Light-boxes mockups',
                        description: 'Our city light-boxes mockups with Western European city atmosphere are ready to feature in your project'
                    },
                ]
            }
        },
        beforeCreate(){
            vm = this;
        },
        methods:{
            goToFreebie(freebieUrl) {
                this.$router.push( {name:'freebie-component', params: {Furl: freebieUrl}} )
            }
        }
    }
</script>