<template>
    <!--SECTION OUR TEAM-->
    <div id="team" class="section-our-team">
        <div class="">
            <div class="container-team">
                <h2 class="main-title">Our team</h2>
                <div class="wrap-founder wrapper row">
                    <div class="founder">
                        <a href="https://www.linkedin.com/in/ninaklymenko/" target="_blank">
                            <img src="https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Team/Nina.png" alt="">
                        </a>
                        <ul class="list-social-founder list-social_founder-mob row">
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/in/ninaklymenko/">
                                    <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                        <title>LinkedIn</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="footer" transform="translate(-810.000000, -204.000000)" fill="#9C9EDE" fill-rule="nonzero">
                                                <g id="Group">
                                                    <g id="icons" transform="translate(531.000000, 204.000000)">
                                                        <g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)">
                                                            <path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="mailto:nina@bozkadesign.com">
                                    <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                        <title>Filled/24x24/mail</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="footer" transform="translate(-531.000000, -207.000000)" fill="#9C9EDE">
                                                <g id="Group">
                                                    <g id="icons" transform="translate(531.000000, 204.000000)">
                                                        <g id="Filled/24x24/mail">
                                                            <path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                        <div class="wrap-founder-name">
                            <a href="https://www.linkedin.com/in/ninaklymenko/" target="_blank" class="wrap-name">
                                <span>Nina</span>
                                <span class="last-name">Klymenko</span>
                            </a>
                            <span class="position">CEO</span>
                        </div>
                    </div>
                    <div class="description-founder">
                        <p>While Nina spent her last 15 years working in the creative industry,
                            her experience and background is very diverse, and includes not only design but
                            also technical and business set of skills.
                        </p>
                        <p>
                            Nina got her education in Ukraine and the USA. After working for product
                            companies and agencies she gathered her own team of professional UI/UX designers
                            and developers, and started Bozka Design agency.
                        </p>
                        <ul class="list-social-founder row">
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/in/ninaklymenko/">
                                    <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                        <title>LinkedIn</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="footer" transform="translate(-810.000000, -204.000000)" fill="#9C9EDE" fill-rule="nonzero">
                                                <g id="Group">
                                                    <g id="icons" transform="translate(531.000000, 204.000000)">
                                                        <g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)">
                                                            <path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="mailto:nina@bozkadesign.com">
                                    <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                        <title>Filled/24x24/mail</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="footer" transform="translate(-531.000000, -207.000000)" fill="#9C9EDE">
                                                <g id="Group">
                                                    <g id="icons" transform="translate(531.000000, 204.000000)">
                                                        <g id="Filled/24x24/mail">
                                                            <path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul class="list-coworker wrapper row">
                    <li class="">
                        <div class="wrap-social-photo">
                            <a href="https://www.linkedin.com/in/yuriy-golikov-294050114/">
                                <img src="https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Team/Yuriy.png" alt="">
                            </a>
                            <ul class="list-social-founder row">
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/in/yuriy-golikov-294050114/">
                                        <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                            <title>LinkedIn</title>
                                            <desc>Created with Sketch.</desc>
                                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="footer" transform="translate(-810.000000, -204.000000)" fill="#9C9EDE" fill-rule="nonzero">
                                                    <g id="Group">
                                                        <g id="icons" transform="translate(531.000000, 204.000000)">
                                                            <g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)">
                                                                <path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="mailto:golikovyuriy8@gmail.com">
                                        <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                            <title>Filled/24x24/mail</title>
                                            <desc>Created with Sketch.</desc>
                                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="footer" transform="translate(-531.000000, -207.000000)" fill="#9C9EDE">
                                                    <g id="Group">
                                                        <g id="icons" transform="translate(531.000000, 204.000000)">
                                                            <g id="Filled/24x24/mail">
                                                                <path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="wrap-coworker">
                            <a href="https://www.linkedin.com/in/yuriy-golikov-294050114" target="_blank" class="wrap-name">
                                <span>Yuriy</span>
                                <span class="last-name">Golikov</span>
                            </a>
                            <span class="position">CTO</span>
                            <p>Being an experienced software engineer, Yuriy successfully manages the development
                                processes in the team.
                            </p>
                            <ul class="list-social-founder list-social_founder-mob row">
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/in/yuriy-golikov-294050114/">
                                        <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                            <title>LinkedIn</title>
                                            <desc>Created with Sketch.</desc>
                                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="footer" transform="translate(-810.000000, -204.000000)" fill="#9C9EDE" fill-rule="nonzero">
                                                    <g id="Group">
                                                        <g id="icons" transform="translate(531.000000, 204.000000)">
                                                            <g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)">
                                                                <path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="mailto:golikovyuriy8@gmail.com">
                                        <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                            <title>Filled/24x24/mail</title>
                                            <desc>Created with Sketch.</desc>
                                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="footer" transform="translate(-531.000000, -207.000000)" fill="#9C9EDE">
                                                    <g id="Group">
                                                        <g id="icons" transform="translate(531.000000, 204.000000)">
                                                            <g id="Filled/24x24/mail">
                                                                <path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="">
                        <div class="wrap-social-photo">
                            <a href="https://www.linkedin.com/in/eman-el-faiz-9a7000173/">
                                <img src="https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Team/Eman.png" alt="">
                            </a>
                            <ul class="list-social-founder row">
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/in/eman-el-faiz-9a7000173/">
                                        <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                            <title>LinkedIn</title>
                                            <desc>Created with Sketch.</desc>
                                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="footer" transform="translate(-810.000000, -204.000000)" fill="#9C9EDE" fill-rule="nonzero">
                                                    <g id="Group">
                                                        <g id="icons" transform="translate(531.000000, 204.000000)">
                                                            <g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)">
                                                                <path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="mailto:contact@bozkadesign.com">
                                        <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                            <title>Filled/24x24/mail</title>
                                            <desc>Created with Sketch.</desc>
                                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="footer" transform="translate(-531.000000, -207.000000)" fill="#9C9EDE">
                                                    <g id="Group">
                                                        <g id="icons" transform="translate(531.000000, 204.000000)">
                                                            <g id="Filled/24x24/mail">
                                                                <path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="wrap-coworker">
                            <a href="https://www.linkedin.com/in/eman-el-faiz-9a7000173" class="wrap-name">
                                <span>Eman</span>
                                <span class="last-name">El Faiz</span>
                            </a>
                            <span class="position">Business Development Manager</span>
                            <p>Eman is a talented and organized manager with exceptional communication skills.</p>
                            <ul class="list-social-founder list-social_founder-mob row">
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/in/eman-el-faiz-9a7000173/">
                                        <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                            <title>LinkedIn</title>
                                            <desc>Created with Sketch.</desc>
                                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="footer" transform="translate(-810.000000, -204.000000)" fill="#9C9EDE" fill-rule="nonzero">
                                                    <g id="Group">
                                                        <g id="icons" transform="translate(531.000000, 204.000000)">
                                                            <g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)">
                                                                <path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="mailto:contact@bozkadesign.com">
                                        <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                            <title>Filled/24x24/mail</title>
                                            <desc>Created with Sketch.</desc>
                                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="footer" transform="translate(-531.000000, -207.000000)" fill="#9C9EDE">
                                                    <g id="Group">
                                                        <g id="icons" transform="translate(531.000000, 204.000000)">
                                                            <g id="Filled/24x24/mail">
                                                                <path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul class="wrap-list-team wrapper">
                    <li>
                        <ul>
                            <li>
                                <span class="name-team-member">Maxym Tsymbalist</span>
                                <span class="position-team-member">Lead UX Designer</span>
                            </li>
                            <li>
                                <span class="name-team-member">Mary Khorunzha</span>
                                <span class="position-team-member">UI/UX Designer</span>
                            </li>
                            <li>
                                <span class="name-team-member">Lana Shkatova</span>
                                <span class="position-team-member">UI/UX Designer</span>
                            </li>
                            <li>
                                <span class="name-team-member">Irina Lisogor</span>
                                <span class="position-team-member">Illustrator</span>
                            </li>
                            <li>
                                <span class="name-team-member">Yaroslav Koliada</span>
                                <span class="position-team-member">Marketing</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li>
                                <span class="name-team-member">Olexiy Zhyvotchenko</span>
                                <span class="position-team-member">BackEnd Developer</span>
                            </li>
                            <li>
                                <span class="name-team-member">Vadim Vereikin</span>
                                <span class="position-team-member">BackEnd Developer</span>
                            </li>
                            <li>
                                <span class="name-team-member">Serhii Lukashov</span>
                                <span class="position-team-member">FrontEnd Developer</span>
                            </li>
                            <li>
                                <span class="name-team-member">Mikhail Zhyrov</span>
                                <span class="position-team-member">WordPress Developer</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li>
                                <span class="name-team-member">Vladyslav Vilihura</span>
                                <span class="position-team-member">Backend Developer</span>
                            </li>
                            <li>
                                <span class="name-team-member">Roman Lesovoy</span>
                                <span class="position-team-member">FullStack Developer</span>
                            </li>
                            <li>
                                <span class="name-team-member">Ecaterina Machedonscaia</span>
                                <span class="position-team-member">Animator</span>
                            </li>
                            <li>
                                <span class="name-team-member">Alina Kostyuk</span>
                                <span class="position-team-member">Illustrator</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li>
                                <span class="name-team-member">Julia Krasilnikova</span>
                                <span class="position-team-member">Marketing</span>
                            </li>
                            <li>
                                <span class="name-team-member">Veronika Kanigina</span>
                                <span class="position-team-member">Illustrator</span>
                            </li>
                            <li>
                                <span class="name-team-member">Anna Nazarova</span>
                                <span class="position-team-member">Lawyer</span>
                            </li>
                            <li>
                                <span class="name-team-member">Yana Lianna</span>
                                <span class="position-team-member">Technical Writer / Copywriter</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'team',
    }
</script>
