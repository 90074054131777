<template>
    <main>
        <MainSection />
        <Services />
        <Clients />
        <Portfolio />
        <Workshop />
        <Industries />
        <Team />
        <GoFreebie />
    </main>
</template>

<script>
    import MainSection from './MainSection';
    import Services from './Services';
    import Workshop from './Workshop';
    import Clients from './Clients';
    import Portfolio from './SectionPortfolio';
    import Industries from './Industries';
    import Team from './Team';
    import GoFreebie from '../partials/GoFreebies';

    export default {
        name: 'home',
        components: {
            MainSection,
            Services,
            Workshop,
            Clients,
            Portfolio,
            Industries,
            Team,
            GoFreebie
        }
    }
</script>