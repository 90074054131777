<template>
    <!--SECTION WORKSHOP-->
    <div class="section-workshop">
        <div class="wrapper workshop-wrapper">
            <section class="container-workshop">
                <strong class="workshop">Schedule meeting</strong>
            </section>
            <a target="_blank"
               href="https://calendly.com/bozkagames/30min?month=2019-05"
               class="common-btn">Contact us</a>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'workshop',

        data(){
            return {

            }
        },
    }
</script>