<template>
    <main>
        <div class="current-portfolio">
            <picture>
                <source srcset="/img/images/portfolio/Human_capital/Human_capital_Header_mobile.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/Human_capital/Human_capital_Header_mobile-iphone.png" media="(max-width: 414px)" />
                <source srcset="/img/images/portfolio/Human_capital/Human_capital_header.png" media="(max-width: 1699px)" />
                <source srcset="/img/images/portfolio/Human_capital/Human_Capital-full-hd.png" media="(min-width: 1700px)" />

                <img class="main-img_portfolio main-img_human" src="/img/images/portfolio/Human_capital/Human_Capital-full-hd.png" alt="">
            </picture>
            <section class="wrapper current-portfolio current-human_capital">
                <h1 class="current-portfolio_title">Human Capital</h1>
                <ul class="portfolio-list_tags">
                    <li>#Web Site</li>
                    <li>#UX Research</li>
                    <li>#Redesign</li>
                    <li>#Recruitment</li>
                </ul>
                <h2 class="current-portfolio_subtitle">
                    <em>Human Capital </em>is a platform that allows people to search and apply for job programs abroad.
                </h2>
                <p class="current-portfolio_description">
                    We analyzed the Human Capital old website KPIs and tested it on target users. Defining the
                    weak sides of the design we suggested the changes.
                    The tests we run for the new version showed better results.
                </p>
            </section>
                <img class="main-bg_human" src="/img/images/portfolio/Human_capital/bg_image_1.png" alt="">
            <div class="wrapper wrapper-human_capital">

                <div class="portfolio-description human-capital_description">
                    <section>
                        <h2>Analysis</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            The reason why the owner of the
                            <a target="_blank" href="https://human-capital.com.ua/"> Human Capital </a>decided to make redesign was
                            in <strong>low conversion </strong>and <strong>high bounce rate.</strong>
                        </p> <br>
                        <p>
                            First, we looked to Google Analytics to get some insights and found
                            out the wrong geographical targeting and high bounce rate on certain pages.
                            Besides that, the tests showed extremely long loading time for the website,
                            mostly because of not optimized graphics.
                        </p>
                    </section>
                </div>

                <picture>
                    <source srcset="/img/images/portfolio/Human_capital/analysis_mobile.png" media="(max-width: 320px)" />
                    <source srcset="/img/images/portfolio/Human_capital/analysis-iphone.png" media="(max-width: 414px)" />

                    <img class="human-capital_analysis" src="/img/images/portfolio/Human_capital/analysis.png" alt="">
                </picture>
                <div class="portfolio-description">
                    <section>
                        <h2>Testing</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            For Human Capital we run the <strong> user testing </strong>with target users twice:
                            First, on the old website together with a couple of competitor’s websites.
                            Second, we tested the redesigned website to check if the UX was improved.
                            And it was done.
                        </p>
                    </section>
                </div>

                <picture>
                    <source srcset="/img/images/portfolio/Human_capital/testing_mobile.png" media="(max-width: 320px)" />
                    <source srcset="/img/images/portfolio/Human_capital/testing-iphone.png" media="(max-width: 414px)" />

                    <img class="human-testing_img" src="/img/images/portfolio/Human_capital/testing.png" alt="">
                </picture>

                <img class="main-bg_human-2" src="/img/images/portfolio/Human_capital/bg_image_2.png" alt="">

                <div class="portfolio-description human-capital_result">
                    <section>
                        <h2>Result</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            Getting used to working with the Western market, some of the insights we got from
                            testing this Eastern European website surprised us a lot. Besides the difference in
                            aesthetic taste, we saw a totally different online habits and behavior.
                            Considering this we redesigned the website in a style that wouldn’t look alien for the
                            Eastern European, improving the users' interaction and architecture.
                        </p>
                    </section>
                </div>
                 <picture>
                    <source srcset="/img/images/portfolio/Human_capital/result_mobile.png" media="(max-width: 320px)" />
                    <source srcset="/img/images/portfolio/Human_capital/result-iphone.png" media="(max-width: 414px)" />

                    <img class="human-capital_result-img" src="/img/images/portfolio/Human_capital/result.png" alt="">
                </picture>
            </div>
        </div>

        <section class="more-projects">
            <h2 class="main-title">More projects</h2>
            <div class="wrapper">
                <MoreProjects :projects="moreProjects"/>
            </div>
        </section>
        <Workshop />
    </main>
</template>

<script>
    import MoreProjects from "../partials/MoreProjects";
    import Workshop from "../Home/Workshop";

    export default {
        name: 'human-capital',

        components: {MoreProjects, Workshop},
        data() {
            return {
                moreProjects: [
                    {
                        image: "/img/images/portfolio/main/Ember.png",
                        image_hover: "/img/images/portfolio/main/Ember_clear.png",
                        title: "Ember Wave",
                        url: "/portfolio/ember",
                        tags: [
                            {
                                name: "#Design Support",
                            },
                            {
                                name: "#Mobile App",
                            },
                            {
                                name: "#IoT",
                            },
                            {
                                name: "#Wearables",
                            },
                            {
                                name: "#User Research"
                            },
                        ],
                    },
                    {
                        image: "/img/images/portfolio/main/Stressguide.png",
                        image_hover: "/img/images/portfolio/main/Stressguide_clear.png",
                        title: "Stress Guide",
                        url: "/portfolio/stress-guide",
                        tags: [
                            {
                                name: "#Mobile App",
                            },
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#User Research",
                            },
                            {
                                name: "#Healthcare",
                            },
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Alacris.png",
                        image_hover: "/img/images/portfolio/main/Alacris_clear.png",
                        title: "Alacris",
                        url: "/portfolio/alacris",
                        tags: [
                            {
                                name: "#Design Support",
                            },
                            {
                                name: "#Demo Prototype",
                            },
                            {
                                name: "#Fintech",
                            },
                            {
                                name: "#Blockchain",
                            },
                            {
                                name: "#Landing Page",
                            },
                        ]
                    },
                ],
            }
        }
    }
</script>