require('./bootstrap');

import Vue from 'vue';
import VueRouter from 'vue-router';
import router from './routes';
import App from './components/MainApp'

Vue.use(VueRouter);

const app = new Vue({
    el: '#app',
    router,
    render: h => h(App),
});