<template>
    <!--SECTION SERVICES-->
    <div id="services" class="section-services">
        <section class="wrapper">
            <h2 class="main-title">Services</h2>
            <ul class="list-services container row col-md-10 col-sm-12">
                <li v-for="service in SERVICES"
                    :key="service.title">
                    <a @click="goToServices(service.url)">
                        <span class="title-services">{{service.title}}</span>
                        <span class="number-services">{{service.number}}</span>
                    </a>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
    import {SERVICES} from '../constants/consts'

    export default {
        name: 'services',
        data(){ 
            return { SERVICES } 
        },
        methods:{
            goToServices(serviceUrl) {
                this.$router.push( {name:'services-page', params: {Surl: serviceUrl}} )
            }
        }
    }
</script>