<template>
    <main> 
        <ServicesMain />
        <ServicesComponent />
        <Services style="background: #25273E" />
        <Workshop />
    </main>
</template>

<script>
    import Services from '../Home/Services'
    import ServicesMain from './ServicesMain'
    import ServicesComponent from './ServicesComponent'
    import Workshop from "../Home/Workshop";

    export default {
        name: 'services-page',
        components: {
            Services,
            ServicesMain,
            ServicesComponent,
            Workshop
        },
    }
</script>