<template>
  <div class="wrapper-article-section">
    <div v-if="!isShowSocialLink" class="show-social-link" @click="toggleShowSocialLink()">
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
        <title>Share</title>
        <desc>Created with Sketch.</desc>
        <g id="Blog,-FAQ,-estimate" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Blog--article" transform="translate(-1386.000000, -470.000000)" fill="#FFFFFF" fill-rule="nonzero" >
            <g id="Share" transform="translate(1386.000000, 470.000000)">
              <path
                d="M8.81853592,16.600753 C7.88192598,17.4691291 6.62795876,18 5.25,18 C2.35050506,18 0,15.6494949 0,12.75 C0,9.85050506 2.35050506,7.5 5.25,7.5 C6.54436816,7.5 7.7293318,7.96841643 8.64462383,8.74498222 L13.6674343,6.57038617 C13.558148,6.14851176 13.5,5.70604647 13.5,5.25 C13.5,2.35050506 15.8505051,0 18.75,0 C21.6494949,0 24,2.35050506 24,5.25 C24,8.14949494 21.6494949,10.5 18.75,10.5 C17.4017984,10.5 16.1722909,9.99180992 15.2425846,9.15653684 L10.2965903,11.2978758 C10.4290474,11.7590465 10.5,12.2462373 10.5,12.75 C10.5,13.1913245 10.4455457,13.6199305 10.3429895,14.0294654 L14.47857,15.6968651 C15.4310009,14.366757 16.9892795,13.5 18.75,13.5 C21.6494949,13.5 24,15.8505051 24,18.75 C24,21.6494949 21.6494949,24 18.75,24 C15.8505051,24 13.5,21.6494949 13.5,18.75 C13.5,18.6630836 13.5021121,18.5766605 13.5062879,18.4907793 L8.81853592,16.600753 Z M18.75,7.5 C19.9926407,7.5 21,6.49264069 21,5.25 C21,4.00735931 19.9926407,3 18.75,3 C17.5073593,3 16.5,4.00735931 16.5,5.25 C16.5,6.49264069 17.5073593,7.5 18.75,7.5 Z M5.25,15 C6.49264069,15 7.5,13.9926407 7.5,12.75 C7.5,11.5073593 6.49264069,10.5 5.25,10.5 C4.00735931,10.5 3,11.5073593 3,12.75 C3,13.9926407 4.00735931,15 5.25,15 Z M18.75,21 C19.9926407,21 21,19.9926407 21,18.75 C21,17.5073593 19.9926407,16.5 18.75,16.5 C17.5073593,16.5 16.5,17.5073593 16.5,18.75 C16.5,19.9926407 17.5073593,21 18.75,21 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
    <ul v-else class="social-link">
      <li class="close-social-link" @click="toggleShowSocialLink()">
        <svg width="9px" height="16px" viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
          <title>Outline/16x16/Arrow</title>
          <desc>Created with Sketch.</desc>
          <g id="Blog,-FAQ,-estimate" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
            <g id="Blog--article" transform="translate(-1224.000000, -554.000000)" fill="#FFFFFF">
              <g id="Outline/16x16/Arrow"
                transform="translate(1228.500000, 562.000000) scale(-1, 1) translate(-1228.500000, -562.000000) translate(1224.000000, 554.000000)"
              >
                <path
                  d="M7.60957619,7.10542736e-15 L8.9931355,1.45349139 L2.764,7.996 L9,14.5465086 L7.61644069,16 L0.00686449724,8.00579735 L0.008,8.003 L-6.30606678e-14,7.99420265 L7.60957619,7.10542736e-15 Z"
                  id="Arrow" />
              </g>
            </g>
          </g>
        </svg>
      </li>
      <li>
        <a target="_blank" href="https://teleg.run/bozkadesign">
          <svg width="30px" height="29px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
            <title>telegram</title>
            <desc>Created with Sketch.</desc>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="footer" transform="translate(-741.000000, -204.000000)" fill="#FFFFFF">
                <g id="Group">
                  <g id="icons" transform="translate(531.000000, 204.000000)">
                    <g id="telegram" transform="translate(210.000000, 0.000000)">
                      <g id="Combined-Shape">
                        <path
                          d="M14.5,0 L15.5,0 C23.5081289,-1.47106941e-15 30,6.49187113 30,14.5 C30,22.5081289 23.5081289,29 15.5,29 L14.5,29 C6.49187113,29 9.80712939e-16,22.5081289 0,14.5 C-9.80712939e-16,6.49187113 6.49187113,1.47106941e-15 14.5,0 Z M20.9731698,8.75283051 L6.33987931,14.4780519 C5.86590461,14.6635109 5.89461871,15.3463877 6.3825183,15.4910139 L10.1009941,16.5934513 L11.4887302,21.0144817 C11.6336931,21.4762732 12.2165701,21.6162714 12.5539848,21.2703737 L14.476533,19.2995041 L18.2487396,22.0794609 C18.710374,22.41967 19.3678212,22.1669116 19.4850304,21.604171 L21.9833971,9.61176558 C22.1057441,9.02434212 21.5301177,8.53492702 20.9731698,8.75283051 Z M19.1385406,11.414242 L12.3414621,17.4489153 C12.2737102,17.5090798 12.2305911,17.5923363 12.2205075,17.6825831 L11.9588155,20.0182 C11.9502685,20.0943698 11.8441031,20.1044454 11.8214392,20.0312645 L10.7447565,16.5480387 C10.6954432,16.3885642 10.7597378,16.215784 10.9010515,16.1278031 L18.9276984,11.1297141 C19.1122753,11.014688 19.3012698,11.2698087 19.1385406,11.414242 Z"
                          fill-rule="nonzero"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
      </li>
      <li>
        <a target="_blank" href="https://www.linkedin.com/company/bozkadesign/">
          <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
            <title>LinkedIn</title>
            <desc>Created with Sketch.</desc>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="footer" transform="translate(-810.000000, -204.000000)" fill="#FFFFFF" fill-rule="nonzero" >
                <g id="Group">
                  <g id="icons" transform="translate(531.000000, 204.000000)">
                    <g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)">
                      <path id="linkedin"
                        d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
      </li>
      <li>
        <a target="_blank" href="https://twitter.com/bozkadesign">
          <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
            <title>twitter</title>
            <desc>Created with Sketch.</desc>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="footer" transform="translate(-880.000000, -207.000000)" fill="#FFFFFF" fill-rule="nonzero" >
                <g id="Group">
                  <g id="icons" transform="translate(531.000000, 204.000000)">
                    <g id="Filled/16x16/twitter" transform="translate(349.000000, 0.000000)">
                      <path
                        d="M29.999951,6.41281451 C28.8957843,6.88589503 27.7110294,7.20673282 26.4662255,7.3499699 C27.7372549,6.61401948 28.7101961,5.44669888 29.1713235,4.06001983 C27.9790686,4.74155723 26.6630392,5.23644087 25.2607843,5.50471416 C24.1378922,4.3464466 22.5406863,3.625 20.7691176,3.625 C17.3703922,3.625 14.6146569,6.28957943 14.6146569,9.57407872 C14.6146569,10.0399073 14.6690196,10.4948818 14.7740196,10.929949 C9.66,10.6815829 5.1252451,8.31248328 2.09019608,4.71254959 C1.55965686,5.58988856 1.25784314,6.61217095 1.25784314,7.70341759 C1.25784314,9.76803177 2.34514706,11.5897307 3.99485294,12.6555245 C2.98627451,12.6229146 2.03769608,12.3546413 1.2072549,11.9087199 L1.2072549,11.9830402 C1.2072549,14.8651295 3.32936275,17.2704887 6.14318627,17.8179368 C5.62764706,17.9520735 5.08401961,18.0263937 4.52161765,18.0263937 C4.12421569,18.0263937 3.73990196,17.988333 3.36308824,17.9158139 C4.14666667,20.2813113 6.41877451,22.0014834 9.11078431,22.0486445 C7.00553922,23.6437805 4.35102941,24.59179 1.46784314,24.59179 C0.971078431,24.59179 0.481764706,24.5627824 0,24.5102179 C2.72387255,26.2014298 5.95764706,27.1875 9.43323529,27.1875 C20.7542157,27.1875 26.942451,18.1206685 26.942451,10.2574646 L26.9218137,9.48710319 C28.1309314,8.65322828 29.1769608,7.6055405 29.999951,6.41281451 Z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
      </li>
    </ul>
    <div class="wrap-article">
      <article class="container-article">
        <router-link tag="span" to="/blog" class="back-to-blog">Back</router-link>
        <h1 class="article-titile">{{currentPost.title}}</h1>
        <div class="article-text" v-html="currentPost.body"></div>
        <button class="up-to-top" @click="scrollToTop()">
          <svg width="9px" height="16px" viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
            <title>Outline/16x16/Arrow</title>
            <desc>Created with Sketch.</desc>
            <g id="Blog,-FAQ,-estimate" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
              <g id="Blog--article" transform="translate(-1224.000000, -554.000000)" fill="#FFFFFF" >
                <g id="Outline/16x16/Arrow"
                  transform="translate(1228.500000, 562.000000) scale(-1, 1) translate(-1228.500000, -562.000000) translate(1224.000000, 554.000000)"
                >
                  <path
                    d="M7.60957619,7.10542736e-15 L8.9931355,1.45349139 L2.764,7.996 L9,14.5465086 L7.61644069,16 L0.00686449724,8.00579735 L0.008,8.003 L-6.30606678e-14,7.99420265 L7.60957619,7.10542736e-15 Z"
                    id="Arrow"
                  />
                </g>
              </g>
            </g>
          </svg>
        </button>
      </article>
      <div class="container-more-otherPosts">
        <div class="wrapper">
          <h2 class="more-articles">More articles</h2>
          <section class="wrapper-articles">
            <div v-for="article in otherPosts" class="blog-article" :key="article.slug">
              <router-link :to="'/post/' +article.slug">
                <img :src="'../'+article.image" :alt="article.title" />
                <div class="article-title">{{article.title}}</div>
              </router-link>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { requestGet, requestPost } from "../../services/axios";

  let vm = {};

  export default {
    name: "Post",
    components: {},
    data() {
      return {
        post: this.$route.params.name,
        isShowSocialLink: false,
        otherPosts: [],
        currentPost: {}
      };
    },
    beforeCreate() {
      vm = this;
    },
    mounted: () => {
      vm.getOtherPosts();
      vm.getPost();
    },
    methods: {
      getPost: () => {
        requestGet("/getPost/"+vm.post, {}).then(response => {
          if (response.status) {
            vm.currentPost = response.data;
          }
        });
      },
      getOtherPosts: () => {
        requestGet("/related/" + vm.post, {}).then(response => {
          if (response.status) {
            vm.otherPosts = response.data;
          }
        });
      },
      toggleShowSocialLink: () => {
        vm.isShowSocialLink = !vm.isShowSocialLink;
      },
      scrollToTop: () => {
        window.scrollTo(pageXOffset, 0);
      }
    }
  };
</script>