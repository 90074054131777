<template>
    <main>
        <div class="current-portfolio">
            <picture>
                <source srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_header_mobile.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_header_mobile-iphone.png" media="(max-width: 414px)" />
                <source srcset="/img/images/portfolio/Stress_guide/Stress_guide_header_img.png" media="(max-width: 1699px)" />
                <source srcset="/img/images/portfolio/Stress_guide/Stress_guide_header_img-full-hd.png" media="(min-width: 1700px)" />

                <img class="main-img_portfolio main-stress_guide"
                     src="/img/images/portfolio/Stress_guide/Stress_guide_header_img-full-hd.png" alt="">
            </picture>
            <section class="wrapper current-portfolio current-portfolio_guide">
                <h1 class="current-portfolio_title">Stress Guide</h1>
                <ul class="portfolio-list_tags">
                    <li>#Mobile App</li>
                    <li>#Redesign</li>
                    <li>#User Research</li>
                    <li>#Healthcare</li>
                </ul>
                <h2 class="current-portfolio_subtitle">
                    <em>Stress Guide </em>is an app that measures and evaluates the stress level.
                </h2>
                <p class="current-portfolio_description">
                    We started working on Stress Guide when the company decided to expand to the Android platform.
                    While working on this we also became in charge of another task: introduction of the
                    monetization model. This had to change the entire structure of the app.
                </p>
            </section>

            <div class="wrapper wrapper-stress_guide">
                <div class="portfolio-description portfolio-story portfolio-basics">
                    <section>
                        <h2>Basics</h2>
                        <div class="horizontal-line"></div>
                        <p>The principle of the measurement in <a target="_blank" href="https://www.kenkou.de/"> Stress Guide </a>is based on scientific research about
                            the miscorrelation of breathing and heartbeat rhythms. And the measurement itself
                            happens in a rather unusual way: user holds their finger on the phone’s camera, that
                            records the characteristics of the pulse.
                        </p>
                    </section>
                    <picture>
                        <source srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_Basics_mobile.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_Basics_mobile-iphone.png" media="(max-width: 414px)" />

                        <img class="img-basis" src="/img/images/portfolio/Stress_guide/Stress_guide_Basics.png" alt="">                     
                    </picture>
                </div>

                <div class="portfolio-description portfolio-process">
                    <section>
                        <h2>Android app</h2>
                        <div class="horizontal-line"></div>
                        <p>When we started working on Stress Guide project the client was planning to redesign
                            their existing iOS app to match the <strong> Android apps requirements.</strong>
                        </p>
                    </section>
                </div>
            </div>

            <picture>
                <source srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_middle_img_mobile.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_middle_img_mobile-iphone.png" media="(max-width: 414px)" />

                <img class="middle-stress_guide"
                     src="/img/images/portfolio/Stress_guide/Stress_guide_middle_img.png" alt="">
            </picture>

            <div class="wrapper">
                <div class="portfolio-description portfolio-story portfolio-basics full-app_redesign">
                    <section>
                        <h2>Full app redesign</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            After that part was done, the next task was to redesign the app, introducing monetization
                            via subscription. That was not only an isolated change by adding the payment flow,
                            but it actually required a <strong> full restructuring </strong>of the app: changing the navigation and
                            user journey completely.
                        </p> <br>
                        <p>
                            Since we were challenged to make such a dramatic change, we suggested to take advantage
                            of it and <strong> redesign the visual style </strong>for the app because the previous one was outdated and,
                            based on material design guidelines looked alien for iOS platform.
                        </p> <br>
                        <p>
                            We were working closely with the developers, stakeholders, and UX writer to come up with
                            the new smooth UX flow and cleaner modern design.
                            We widely used <strong> user testing </strong>for
                            perfecting our design.
                        </p>
                        <picture>
                            <source srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_app_design_mobile.png" media="(max-width: 320px)" />
                            <source srcset="/img/images/portfolio/Stress_guide/mobile/Stress_guide_app_design_mobile-iphone.png" media="(max-width: 414px)" />

                            <img class="bottom-img_stress-guide" src="/img/images/portfolio/Stress_guide/Stress_guide_app_design.png" alt="">                        
                        </picture>
                    </section>
                </div>
            </div>
        </div>

        <section class="more-projects">
            <h2 class="main-title">More projects</h2>
            <div class="wrapper">
                <MoreProjects :projects="moreProjects"/>
            </div>
        </section>
        <Workshop />
    </main>
</template>

<script>
    import MoreProjects from "../partials/MoreProjects";
    import Workshop from "../Home/Workshop";

    export default {
        name: 'stress-guide',
        components: {MoreProjects, Workshop},
        data() {
            return {
                moreProjects: [
                    {
                        image: "/img/images/portfolio/main/Alacris.png",
                        image_hover: "/img/images/portfolio/main/Alacris_clear.png",
                        title: " Alacris",
                        url: "/portfolio/alacris",
                        tags: [
                            {
                                name: "#Design Support",
                            },
                            {
                                name: "#Demo Prototype",
                            },
                            {
                                name: "#Fintech",
                            },
                            {
                                name: "#Blockchain",
                            },
                            {
                                name: "#Landing Page",
                            },
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Jobtip.png",
                        image_hover: "/img/images/portfolio/main/Jobtip_clear.png",
                        title: "Jobtip",
                        url: "/portfolio/jobtip",
                        tags: [
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Landing Page",
                            },
                            {
                                name: "#Rebranding",
                            },
                            {
                                name: "#Illustrations",
                            }
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/health_wizz.png",
                        image_hover: "/img/images/portfolio/main/health_wizz_clear.png",
                        title: "Health Wizz",
                        url: "/portfolio/health",
                        tags: [
                            {
                                name: "#Webapp",
                            },
                            {
                                name: "#Blockchain",
                            },
                            {
                                name: "#Healthcare",
                            },
                            {
                                name: "#Design System",
                            },
                            {
                                name: "#SaaS",
                            },
                            {
                                name: "#Fintech",
                            }
                        ]
                    },
                ],
            }
        }
    }
</script>