<template>
  <div class="successfully-send">
    <img src="/img/images/other/check.svg" alt />
    <h2>
      Your message was successfully sent. We will
      <br />respond to you as soon as possible.
    </h2>
    <a href="/" class="link-to-main-page">Ok</a>
  </div>
</template>

<script>
export default {
  name: "successfully-send",

  data() {
    return {};
  }
};
</script>