<template>
    <div>
        <ul class="list-freebies row col-md-12 col-sm-8">
                <li v-for="freebie in this.freebies"
                    :key="freebie.url"
                    >
                    <img :src="freebie.image" alt="">
                    <section class="freebies-item_section">
                        <h3 @click="goToFreebie(freebie.url)" :to="freebie.url">{{freebie.caption}}</h3>
                        <p>{{freebie.description}}</p>
                        <a @click="goToFreebie(freebie.url)" class="item-freebie_link" :to="freebie.url">Link more and download</a>
                    </section>
                </li>
            </ul>
    </div>
</template>

<script>
    export default {
        name: 'custom-freebie',
        props: ['freebies'],
        methods:{
            goToFreebie(freebieUrl) {
                this.$router.push( {name:'freebie-component', params: {Furl: freebieUrl}} )
            }
        }
    }
</script>