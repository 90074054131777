<template>
       <div>
              <div class="wrap-how-we-work">
                     <div class="wrapper">
                            <h2 class="caption-work">How we work</h2>
                            <div class="wrap-animation_work wrap-sharp">
                                   <section>
                                          <h3 class="title-work">Sharp</h3>
                                          <p class="description-work">
                                                 Our designs are backed by our technical, business-oriented and deep knowledge of the
                                                 development process. Our interfaces are not only functional and beautiful but also enhanced
                                                 in a way that optimizes both development and maintenance.
                                          </p>
                                   </section>
                                   <img width="203" 
                                          height="203" src="/img/images/gif/Cut_full.gif" 
                                          alt="">
                            </div>
                            <div class="wrap-animation_work wrap-flexible">
                                   <section>
                                          <h3 class="title-work">flexible</h3>
                                          <p class="description-work">
                                                 Being an efficient and well-equipped team, we have an organized design process 
                                                 that lets us deliver high-quality results in the most efficient manner.
                                          </p>
                                   </section>
                                   <img width="203" 
                                          height="203" src="/img/images/gif/Flexible_full.gif" 
                                          alt="">
                            </div>
                            <div class="wrap-animation_work wrap-human_work">
                                   <section>
                                          <h3 class="title-work">Human</h3>
                                          <p class="description-work">
                                                 Experience taught us that reality could be different from plans.
                                                 That’s why we never cut ties with our clients after delivering the 
                                                 final design, We are always lending a helping hand if any
                                                 touch-ups or modifications need to be done. 
                                          </p>
                                   </section>
                                   <img width="203" 
                                          height="203" 
                                          src="/img/images/gif/Heart_full.gif" 
                                          alt="">
                            </div>
                     </div>
              </div>
              <Services />
              <Workshop />
       </div>
</template>

<script>
    import Services from './Services';
    import Workshop from './Workshop';
    export default {
        name: 'how-we-work',
        components: {
           Services,
           Workshop
        }
    }
</script>