<template>
  <div>
    <div class="wrap-blog">
      <div class="wrapper">
        <div class="blog-illustration">
          <img srcset="/img/images/blog/Blog_illustration.png" alt width="547" height="391" />
        </div>
        <section class="wrapper-articles">
          <div class="blog-article" v-for="article in articles" :key="article.slug">
            <router-link :to="'/post/' +article.slug">
              <img :src="article.image" :alt="article.title" />
              <div class="article-title">{{article.title}}</div>
            </router-link>
          </div>
          <div v-if="articlesInDevelopment === 0" class></div>
          <div
            v-if="articlesInDevelopment === 1"
            class="blog-article article-in-development"
          >Article in development</div>
          <div
            v-if="articlesInDevelopment === 1"
            class="blog-article article-in-development"
          >Article in development</div>
          <div
            v-if="articlesInDevelopment === 2"
            class="blog-article article-in-development"
          >Article in development</div>
        </section>
      </div>
      <Services />
    </div>
  </div>
</template>
<script>
  import Services from "../Home/Services";
  import { requestGet, requestPost } from "../../services/axios";

  let vm = {};
  
  export default {
    name: "blog",
    components: {
      Services
    },
    data() {
      return {
        articles: [],
        articlesInDevelopment: 0
      };
    },
    beforeCreate() {
      vm = this;
    },
    mounted: () => {
      vm.getPosts();
    },
    methods: {
      getPosts: () => {
        requestGet("/avalablePosts", {}).then(response => {
          if (response.status) {
            vm.articles = response.data;
            vm.articlesInDevelopment = vm.articles.length % 3;
          }
        });
      }
    }
  };
</script>