<template>
        <section class="section-main">
            <div ref="wrapCircles" class="wrap-circles">
                <img v-if="loading" 
                            ref="mainCircle" 
                            class="main-circle" 
                            src="https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/img_1.png" 
                            alt="">
                        <img v-if="loadingSecond" 
                            ref="secondCircle" 
                            class="second-circle" 
                            src="https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/img_2.png" 
                            alt="">
            </div>
            <section class="wrapper-captions">
                <h2 ref="firstTitle"
                    v-if="firstTitle" 
                    class="title-h2 first-title">You have an idea…</h2>
                    
                <h2 ref="secondTitle" 
                    v-if="secondTitle"
                    class="title-h2 second-title">…that needs to be built.</h2>

                <h2 ref="thirdTitle"
                    v-if="thirdTitle" 
                    class="title-h2 third-title">We know how. We will help.</h2>
                <a href="/get-estimate" class="get-estimate go-freebies">Get free estimate</a>
            </section>
            <section class="section-clients row wrapper container">
                <h2 class="main-title">Our happy clients</h2>
                <ul class="list-clients row col-md-10 col-sm-10 col-xs-12">
                    <li>
                            <img src="/img/images/main_section/happy_clients/at_t.png" alt="">
                    </li>
                    <li>
                            <img src="/img/images/main_section/happy_clients/verizon.png" alt="">
                    </li>
                    <li>
                            <img src="/img/images/main_section/happy_clients/european-union.png" alt="">
                    </li>
                    <li>
                            <img src="/img/images/main_section/happy_clients/Mit.png" alt="">
                    </li>
                    <li>
                            <img src="/img/images/main_section/happy_clients/embr.png" alt="">
                    </li>
                </ul>
            </section>
        </section>
</template>

<script>
let vm = {};

    export default {
        name: 'main-section',
        data() {
            return {
              loading: true,
              loadingSecond: false,
              firstTitle: false,
              secondTitle: false,
              thirdTitle: false,
            }
        },

          beforeCreate(){
            vm = this;
        },
        beforeMount: () => {
            vm.twistCircles();
            vm.slideText();
        },
        mounted: () => {
            vm.resizeCircle();
        },

        methods: {
            twistCircles: () => {
                setTimeout(() => {
                    vm.$refs.mainCircle.classList.add('activeMainCircle');
                    setTimeout(() => {
                        vm.loading = false;
                    }, 7000);
                      setTimeout(() => {
                            vm.loadingSecond = true;
                            setTimeout(() => {
                                vm.$refs.secondCircle.classList.add('activeSecondCircle');
                            }, 2000)
                        }, 5000);
                }, 2000);
                
            },
            
            slideText: () => {
                vm.firstTitle = true; 
                setTimeout(() => {
                    vm.$refs.firstTitle.classList.add('active-first_title');
                     setTimeout(() => {
                        vm.firstTitle = false; 
                        vm.secondTitle = true;
                        setTimeout(() => {
                            vm.$refs.secondTitle.classList.add('active-second_title');
                            setTimeout(() => {
                                vm.secondTitle = false;
                                vm.thirdTitle = true;                                                                
                            }, 3000);
                        }, 3000);         
                     }, 2000);
                }, 2000);
            },

            countResize:  () => {
                const imgWidth = 479;
                const calculateWidth = `${-(imgWidth - screen.width)/2}px`;
                if (screen.width <= 479) {
                    vm.$refs.wrapCircles.style.left = calculateWidth;
                }
            },

            resizeCircle: () => {
                vm.countResize();
                window.addEventListener('resize', () => {
                    vm.countResize();
                });
            },
        }
    }
</script>