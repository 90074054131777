<template>
    <!--SECTION WORKSHOP-->
    <div class="section-main_freebies">
        <div class="wrapper workshop-wrapper">
            <img src="https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Mail_notification_freebies_Aprl_08.png" alt="">
            <section class="container-workshop">
                <strong class="workshop">Check out our freebies</strong>
            </section>
            <router-link class="go-freebies" to="/freebies">Go to freebies</router-link>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'main-freebies',

        data(){
            return {

            }
        },
    }
</script>