<template>
    <!--SECTION PORTFOLIO-->
    <div id="portfolio" class="section-portfolio">
        <div class="wrapper">
            <section class="container-portfolio">
                <h2 class="main-title">Portfolio</h2>
                <MoreProjects :projects="moreProjects"/>
            </section>
            <section class="more-clients">
                <h2 class="main-title">more clients</h2>
                <ul class="list-more-clients row container">
                    <li><img src="/img/images/more_clients/Leaf_labs.png" alt=""></li>
                    <li><img src="/img/images/more_clients/Space.png" alt=""></li>
                    <li><img src="/img/images/more_clients/Jobtip.png" alt=""></li>
                    <li><img src="/img/images/more_clients/Plebian.png" alt=""></li>
                    <li><img src="/img/images/more_clients/legicash.png" alt=""></li>
                    <li class="europ"><img src="/img/images/more_clients/Europe.png" alt=""></li>
                    <li><img src="/img/images/more_clients/kenkou.png" alt=""></li>
                    <li><img src="/img/images/more_clients/At_T.png" alt=""></li>
                    <li><img src="/img/images/more_clients/verizon.png" alt=""></li>
                    <li><img src="/img/images/more_clients/Columbia.png" alt=""></li>
                    <li><img src="/img/images/more_clients/MIT.png" alt=""></li>
                    <li><img src="/img/images/more_clients/Figur%208.png" alt=""></li>
                    <li><img src="/img/images/more_clients/Ember.png" alt=""></li>
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
    import MoreProjects from "../partials/MoreProjects";

    export default {
        name: 'portfolio',
        components: {MoreProjects},
        data() {
            return {
                moreProjects: [
                    {
                        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Ember.png",
                        image_hover: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Ember_clear.png",
                        title: "Ember Wave",
                        url: "/portfolio/ember",
                        tags: [
                            {
                                name: "#Design Support",
                            },
                            {
                                name: "#Mobile App",
                            },
                            {
                                name: "#IoT",
                            },
                            {
                                name: "#Wearables",
                            },
                            {
                                name: "#User Research"
                            },
                        ],
                    },
                    {
                        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Stressguide.png",
                        image_hover: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Stressguide_clear.png",
                        title: " Stress Guide",
                        url: "/portfolio/stress-guide",
                        tags: [
                            {
                                name: "#Mobile App",
                            },
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#User Research",
                            },
                            {
                                name: "#Healthcare",
                            },
                        ]
                    },
                    {
                        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Alacris.png",
                        image_hover: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Alacris_clear.png",
                        title: " Alacris",
                        url: "/portfolio/alacris",
                        tags: [
                            {
                                name: "#Design Support",
                            },
                            {
                                name: "#Demo Prototype",
                            },
                            {
                                name: "#Fintech",
                            },
                            {
                                name: "#Blockchain",
                            },
                            {
                                name: "#Landing Page",
                            },
                        ]
                    },
                    {
                        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Jobtip.png",
                        image_hover: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Jobtip_clear.png",
                        title: "Jobtip",
                        url: "/portfolio/jobtip",
                        tags: [
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Landing Page",
                            },
                            {
                                name: "#Rebranding",
                            },
                            {
                                name: "#Illustrations",
                            }
                        ]
                    },
                    {
                        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/health_wizz.png",
                        image_hover: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/health_wizz_clear.png",
                        title: "Health Wizz",
                        url: "/portfolio/health",
                        tags: [
                            {
                                name: "#Webapp",
                            },
                            {
                                name: "#Blockchain",
                            },
                            {
                                name: "#Healthcare",
                            },
                            {
                                name: "#Design System",
                            },
                            {
                                name: "#SaaS",
                            },
                            {
                                name: "#Fintech",
                            }
                        ]
                    },
                    {
                        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Human_capital.png",
                        image_hover: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Human_capital_clear.png",
                        title: "Human Capital",
                        url: "/portfolio/human-capital",
                        tags: [
                            {
                                name: "#Web Site",
                            },
                            {
                                name: "#UX Research",
                            },
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Recruitment",
                            },
                        ]
                    },
                    {
                        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Infra.png",
                        image_hover: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Infra_clear.png",
                        title: "Infra",
                        url: "/portfolio/infra",
                        tags: [
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Rebranding",
                            },
                            {
                                name: "#Landing page",
                            },
                            {
                                name: "#Marketing design",
                            },
                            {
                                name: "#SaaS",
                            },
                            {
                                name: "#Cyber Security",
                            },
                        ]
                    },
                    {
                        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Earth_rating.png",
                        image_hover: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Portfolio/Earth_rating_clear.png",
                        title: "Earth Rating",
                        url: "/portfolio/rating",
                        tags: [
                            {
                                name: "#Web App Prototyping",
                            },
                            {
                                name: "#Presentation",
                            },
                            {
                                name: "#Ecommerce",
                            },
                            {
                                name: "#Environment",
                            },
                        ]
                    },
                ],
            }
        }
    }
</script>