<template>
    <div>
        <ul class="list-portfolio  container wrapper row col-md-12 col-sm-12 col-xs-12">
            <li v-for="portfolio in this.projects" 
                class="item-portfolio"
                :key="portfolio.url">
                <router-link :to="portfolio.url">
                    <a class="link-portfolio">
                        <img class="portfolio-image" :src="portfolio.image" alt="">
                        <img class="portfolio-image_hover" :src="portfolio.image_hover" alt="">
                        <section class="wrap-description-portfolio">
                            <h3>{{portfolio.title}}</h3>
                            <ul class="list-tags">
                                <li v-for="tags in portfolio.tags"
                                    :key="tags.name">{{tags.name}}</li>
                            </ul>
                        </section>
                    </a>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'custom-portfolio',
        props: ['projects'],
    }
</script>