export const SERVICES = [
    {
        title: 'User research',
        number: '01.',
        url: 'user-research'
    },
    {
        title: "Design audit",
        number: "02.",
        url: 'design-audit'
    },
    {
        title: "Apps prototyping",
        number: "03.",
        url: 'app-prototype'
    },
    {
        title: "Presentation & Marketing Design",
        number: "04.",
        url: 'present-design'
    },
    {
        title: "Product page design",
        number: "05.",
        url: 'product-page'
    },
    {
        title: "Development",
        number: "06.",
        url: 'development'
    },
    {
        title: "Design support",
        number: "07.",
        url: "design-support"
    },
    {
        title: "Corporate 2D & 3D illustrations",
        number: "08.",
        url: "illustration"
    }
];

export const CLIENTS = [
    {
        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/David.png",
        description: "“Bozka Design has become an integral extension of our team, contributing to " +
            "our app's UI/UX design on a nearly daily basis. Founder Nina Klymenko's extensive " +
            "experience in the U.S. makes Bozka Design extremely easy to work with for an American " +
            "company like Embr Labs, and her local team is talented, dynamic, and a " +
            "pleasure to collaborate with.”",
        link: "https://www.linkedin.com/in/dctanugi/",
        name: "David Cohen-Tanugi, CPO at ",
        project: "Embr Labs",
        project_link: "https://embrlabs.com/"
    },
    {
        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Christopher.png",
        description: "“I worked with BozkaDesign on multiple projects, particularly in blockchain " +
            "field. They always impressed me with the quality and speed with surprisingly deep " +
            "understanding of technical and business aspects. It difficult to find a design company " +
            "with these traits.”",
        link: "https://www.linkedin.com/in/chrisswenor/",
        name: "Chris Swenor, CEO, CO-Founder at ",
        project: "Alacris",
        project_link: "https://alacris.io/"
    },
    {
        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Raj.png",
        description: "“Bozka Design led by Nina Klymenko has been terrific to work with. With very few\n" +
            "documented requirements, Nina ran with two very complex Health Wizz products and\n" +
            "turned them from concepts to reality. She takes ownership, does independent research,\n" +
            "uses progressive design techniques and pleasantly surprises us at every milestone.\n" +
            "Her working relationships with the executive team at Health Wizz and the rest of the\n" +
            "company is excellent. We would recommend Nina and Bozka Design very highly”",
        link: "https://www.linkedin.com/in/rajsharmar/",
        name: "Raj Sharma , CEO at ",
        project: "Healthwizz, LLC",
        project_link: "https://www.healthwizz.com/"
    },
];

export const INDUSTRIES = [
    {
        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Industries/Helth.png",
        title: "Healthcare",
        subtitle: "Working on big projects for the US Healthcare we started to love the challenges " +
            "this field provides. We are excited to solve the tangled puzzles made from the " +
            "regulations, management complexity, and responsibility of every decision, " +
            "providing the interfaces that are easy to work with."
    },
    {
        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Industries/blockchain.png",
        title: "Blockchain",
        subtitle: "Despite its ups and downs, we understand that blockchain technology is here " +
            "to stay. We are involved in its development, excited about its various applications, " +
            "and eager to create clear human interfaces for blockchain products."
    },
    {
        image: "https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Industries/Iot.png",
        title: "IoT",
        subtitle: `The way in which technology is getting more woven into our physical life is, indeed,
         fascinating. Equipped with a solid technical understanding, we’re able to proceed efficiently 
         and accordingly while creating the design suitable for the technology.`
    },
];

export const HeaderMenu = [
    {
        name:'Portfolio',
        href:'/#portfolio',
        mobile:true,
    },
    {
        name:'Industries',
        href:'/#industries',
        mobile:true,
    },
    {
        name:'<img src="/img/images/logo/logo.svg" alt />',
        href:'/',
        mobile:false,
    },
    {
        name:'Team',
        href:'/#team',
        mobile:true,
    },
    {
        name:'How we work',
        href:'/how-we-work',
        mobile:true,
    },
    /* {
        name:'Blog',
        href:'/blog',
        mobile:true,
    }, */
];