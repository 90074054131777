<template>
    <div> 
        <div class="container-services" 
             v-for="service in getPageServices"
             :key="service.url">
             <div class="wrapper main-services__description">
                <img :src="service.mainImg" alt="">
                <span class="services-text">{{ service.title }}</span>
                <div class="wrap-services__description">
                    <p v-for="paragraph in service.mainDescription" 
                       :key="paragraph.text">
                        {{paragraph.text}}
                    </p>
                </div>
            </div>
            <h2 class="title-like">why we love it</h2>
            <p class="wrapper row col-md-8 col-md-8 like-description">{{service.descriptionLike}}</p>
        </div>
    </div>
</template>

<script>
    let vm = {};
    export default {
        name: 'services-main',
        data() {
            return {
                serviceUrl: this.$route.params.Surl,

                services: [
                    {
                        title: 'User research',
                        url: 'user-research',
                        mainImg: '/img/images/page-services/Services_banners/User_research.png',
                        mainDescription: [
                            {
                                text: 'You are starting a new project/feature, or maybe you’re getting closer to its development or even its release. You need to be sure that your product is addressing the user’s needs and is designed perfectly. We know how to do that for you with user research.',
                            },
                            {
                                text: 'User research (or UX research) includes a list of qualitative and quantitative methods which are focusing on understanding user behavior and needs. We can suggest the ones that match the requirements and budget depending on the project. ',
                            }
                        ],
                        descriptionLike: 'During the different stages of product development, there are some User Research methods which are most efficient. The properly selected method can significantly increase the quality of the final product and help save a lot of the development budget.'
                    },
                    {
                        title: "Design audit",
                        url: 'design-audit',
                        mainImg: '/img/images/page-services/Services_banners/Design_audit.png',
                        mainDescription: [
                            {
                                text: 'If your website or app doesn’t fulfill its function: low conversion, bad retention, high bounce rate, etc, and you’re not sure how to fix it, we can analyze what’s wrong and offer you the solution. In many cases, the project Stakeholders lose their product’s users and money because of the bad UX,and here is when we come in, to help you to get that money back.',
                            }
                        ],
                        descriptionLike: 'Improving UX, especially in a way we can measure, is a part of making the-world-a-better-place mission all designers are dedicated too. And we have enough skills and experience to do it successfully.'
                    },
                    {
                        title: "Apps prototyping",
                        url: 'app-prototype',
                        mainImg: '/img/images/page-services/Services_banners/Apps_design.png',
                        mainDescription: [
                            {
                                text: 'It’s almost impossible to imagine the product or feature development without prototyping. Prototyping is a part of the lean development process and is essential for evaluating the assumptions people make regarding product usage.',
                            }
                        ],
                        descriptionLike: 'Prototyping is the core part of our process,when we can turn mere ideas, into something visible and realistic.'

                    },
                    {
                        title: "Presentation & Marketing Design",
                        url: 'present-design',
                        mainImg: '/img/images/page-services/Services_banners/Presentation_Marketing_Design.png',
                        mainDescription: [
                            {
                                text: 'Presentation is the key part of the startup’s life. Together with marketing, they help attract investors and users, delivering to them crucial information about your product.'
                            }
                        ],
                        descriptionLike: 'Presentations we created for our clients helped them to attract investment. And the marketing materials brought the clients.'

                    },
                    {
                        title: "Product page design",
                        url: 'product-page',
                        mainImg: '/img/images/page-services/Services_banners/Product_page_design.png',
                        mainDescription: [
                            {
                                text: 'Every product can benefit from a well-designed landing page that can attract traffic and convert users. A Good landing page, tells users about how the product can address their needs and create a trust.',
                            }
                        ],
                        descriptionLike: 'We are pleased to see how the products to which we designed the landing page for, succeeded in the market.'

                    },
                    {
                        title: "Development",
                        url: 'development',
                        mainImg: '/img/images/page-services/Services_banners/Development.png',
                        mainDescription: [
                            {
                                text: 'If you are looking for end-to-end service, we can take care of the development part as well. We are closely collaborating with a trustworthy development team with various skills, with whom we used to build multiple products in the past and developed a smooth collaborative process.',
                            },
                            {
                                text: 'We can manage the projects and the developers’ work so you don’t need to worry about solving routine technical problems, but focus on the fun part: your business.',
                            }
                        ],
                        descriptionLike: 'Taking the responsibility for the development we ensure that the design we created is implemented as intended.'

                    },
                    {
                        title: "Design support",
                        url: "design-support",
                        mainImg: '/img/images/page-services/Services_banners/Design_support.png',
                        mainDescription: [
                            {
                                text: 'What makes us different from many agencies, is that: we don’t disappear once we deliver  the design assets. Taking full responsibility for the product we worked on, we support the project on the implementation phase and after it’s release.',
                            },
                            {
                                text: 'Complex products tend to evolve and improve their features based on the usage data. Any old enough digital product you can point on had changed since the moment of its first release. Products that don’t release the updates to meet their user’s needs very soon can find themselves outside of the market. To prevent it we provide the support that helps the products grow and adapt once it’s out in the market.',
                            }
                        ],
                        descriptionLike: 'Since we are responsible for our baby-designs, which we have created, we are ready to take responsibility for how they develop.'
                    },
                    {
                        title: "Corporate 2D & 3D illustrations",
                        url: "illustration",
                        mainImg: '/img/images/page-services/Services_banners/Corporate_2D_3D_Illustrations.png',
                        mainDescription: [
                            {
                                text: '2D and 3D illustrations can make your website or app stand out. Illustrations not only make your product aesthetically pleasant but also help the user understand better the functionality or concept behind it.',
                            },
                            {
                                text: 'We can offer a high variety of illustrative styles that will make your product look original and fresh.',
                            }
                        ],
                        descriptionLike: 'Some of our team members have experience in 3D and game art and are happy to have an opportunity to create something beautiful and impressive for our client’s products.'
                    },
                ],
            }
        },
        beforeCreate(){
            vm = this;
        },
        computed: {
            getPageServices: () => vm.services.filter(item => vm.serviceUrl === item.url )
        }
    }
</script>