<template>
    <div>
        <Header/>
            <router-view :key="$route.fullPath"></router-view>
        <Footer />
    </div>
</template>

<script>

    import Header from './partials/Header.vue';
    import Footer from './partials/Footer.vue';

    export default {
        name: 'App',
        components: {
            Header,
            Footer
        }
    }
</script>