<template>
    <div class="section-freebies">
        <section class="wrapper wrap-freebie_page"
                 v-for="feebie in getPageFreebies"
                 :key="feebie.url">
            <h2 class="main-title">{{feebie.caption}}</h2>
            <div class="container-freebie_description">
                <img :src="feebie.image" alt="">
                <div class="wrap-freebie_description">
                    <p
                     v-for="descriptions in feebie.description"
                    :key="descriptions.text"
                    >{{descriptions.text}}</p>
                    <a target="_blank" download :href="feebie.download" class="download-freebies">Download for free</a>
                </div>
            </div>
            <ul class="freebie-list_images">
                <li v-for="images in feebie.list_images"
                    :key="images.image">
                    <img :src="images.image" alt="">
                </li>
            </ul>
        </section>
        <section class="more-freebies">
            <h2 class="main-title">More freebies</h2>
            <div class="wrapper">
                <MoreFreebie :freebies="moreFreebie"/>
            </div>
        </section>
        <Workshop />
    </div>
</template>

<script>

import Workshop from '../Home/Workshop'
import MoreFreebie from "../partials/MoreFreebie";

    let vm = {};
    export default {
        name: 'freebies-page',
        components: {
            Workshop,
            MoreFreebie
        },
        data() {
            return {
                freebieUrl: this.$route.params.Furl,
                moreFreebie: [
                    {
                        url: 'people-illustrations',
                        image: 'https://bozkadesign.s3.eu-central-1.amazonaws.com/Main+page/Mail_notification_freebies_Aprl_08.png',
                        caption: 'Everyday icons set',
                        description: 'Download great icons set designed specifically for blockchain projects. The set has 36 flat and outlines icons in .sketch, .png and .svg formats…'
                    },
                    {
                        url: 'city-light-boxes',
                        image: '../img/images/freebies/AD_cover_img.png',
                        caption: 'City Light-boxes mockups',
                        description: 'Our city light-boxes mockups with Western European city atmosphere are ready to feature in your project'
                    },
                ],

                list_freebies: [
                    {
                        image: '/img/images/freebies/freebies01.png',
                        url: 'people-illustrations',
                        caption: 'Corporate people illustrations',
                        download: '/download/BozkaDesign_illustrations.zip',
                        description: [
                            {
                               text: 'illustrations of bloated people are trendy and fun, especially if they all are into technologies.'
                            },
                            {
                               text: 'We created these eight illustrations where the portrayed people are obviously in a good mood  in their work environment. Happiness is contagious, so go ahead and use our fun and unique illustrations and spread the good energy.'
                            },
                            {
                               text: 'The archived package contains files in PNG and AI formats.'
                            },
                        ],
                        list_images: [
                            {
                                image: '/img/images/freebies/New_incoming_message_freebies_Aprl_08.png'
                            }
                        ]
                    },
                    {
                        image: '../img/images/freebies/AD_prev_img_1.png',
                        url: 'city-light-boxes',
                        caption: 'City Light-boxes mockups',
                        download: 'https://drive.google.com/drive/folders/1EL-owB5k8iqKV98kdqTUSJV6_7fw7KsD',
                        description: [
                            {
                               text: 'City Light-boxes mockups.'
                            },
                            {
                               text: 'Realistic non-stock pictures never cease to   keep being trendy. We say yes to the mockups that reflect the real life instead of plastic 3D models! Our city light-boxes mockups with Western European city atmosphere are ready to feature in your project.'
                            },
                            {
                               text: ' Absolutely free for commercial and personal use. Download and use it for your needs!'
                            },
                        ],
                        list_images: [
                            {
                                image: '../img/images/freebies/AD_prev_img_2.png'
                            },
                            {
                                image: '../img/images/freebies/AD_prev_img_3.png'
                            }
                        ]
                    }
                ]
            }
        },
        beforeCreate(){
            vm = this;
        },

        computed: {
            getPageFreebies: () => vm.list_freebies.filter(item => vm.freebieUrl === item.url )
        }
    }
</script>
