<template>
    <!--SECTION INDUSTRIES-->
    <div id="industries" class="section-industries">
        <h2 class="main-title">Industries</h2>
        <div class="wrapper">
            <section class="wrap-industries">
                <ul class="list-industries">
                    <li v-for="industrie in INDUSTRIES"
                        :key="industrie.title">
                        <img :src="industrie.image" alt="">
                        <h3>{{industrie.title}}</h3>
                        <p>{{industrie.subtitle}}</p>
                    </li>
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
    import {INDUSTRIES} from '../constants/consts'

    export default {
        name: 'industries',
        data(){ return { INDUSTRIES } },
    }
</script>