<template>
    <main>
        <div class="current-portfolio">

            <picture>
                <source srcset="/img/images/portfolio/Infra/Mobile/Infra_header_mobile.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/Infra/Mobile/Infra_header_mobile-iphone.png" media="(max-width: 414px)" />
                <source srcset="/img/images/portfolio/Infra/Infra_header_img.png" media="(max-width: 1699px)" />
                <source srcset="/img/images/portfolio/Infra/Infra_header_img-full-hs.png" media="(min-width: 1700px)" />

                <img class="main-img_portfolio infra-main_img" src="/img/images/portfolio/Infra/Infra_header_img-full-hs.png" alt="">
            </picture>

            <picture>
                <source srcset="/img/images/portfolio/Infra/Mobile/Infra_bg_image_1_mobile.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/Infra/Mobile/Infra_bg_image_1_mobile-iphone.png" media="(max-width: 414px)" />

                <img class="main-img_portfolio infra-main_bg" src="/img/images/portfolio/Infra/Infra_bg_image_1.png" alt="">
            </picture>

            <section class="wrapper current-portfolio current-infra">
                <h1 class="current-portfolio_title">INFRA</h1>
                <ul class="portfolio-list_tags">
                    <li>#Redesign</li>
                    <li>#Rebranding</li>
                    <li>#Landing page</li>
                    <li>#Marketing design</li>
                    <li>#SaaS</li>
                    <li>#Cyber Security</li>
                </ul>
                <h2 class="current-portfolio_subtitle">
                   <em>INFRA </em>offers hardware and software solutions for automatic vulnerability assessment.
                </h2>
                <p class="current-portfolio_description">
                    The previous INFRA’s website required serious redesign because not only the look and feel did not
                    match the website goals, but the entire structure and navigation were confusing for users.
                </p>
            </section>

            <div class="portfolio-description infra-description wrapper">
                <section>
                    <h2>TARGET Users (TU)</h2>
                    <div class="horizontal-line"></div>
                    <p>
                        <a target="_blank" href="https://www.infrascan.net/">INFRA </a>offers their services for
                        <strong>financial, telecom, government,</strong> and similar institutions.
                    </p> <br>
                    <p>
                        We analyzed the particular cybersecurity services. INFRA provides automated (unlike their competitors)
                         vulnerability assessment that let us separate two main types of users with two different goals.
                    </p> <br>
                    <p>
                        <strong>The first type </strong>
                        is cybersecurity departments of big corporations who are interested
                        in detailed technical information about INFRA’s hardware solutions.
                        <strong>The second type</strong>of users include managers of the middle and small
                        size companies who would be
                        interested in a lighter software solution and should be able to test it and
                        purchase right on the website.
                    </p>
                </section>

                <picture>
                    <source srcset="/img/images/portfolio/Infra/Mobile/target_user_img_mobile.png" media="(max-width: 320px)" />
                    <source srcset="/img/images/portfolio/Infra/Mobile/target_user_img_mobile-iphone.png" media="(max-width: 414px)" />

                    <img class="infra-target_user" src="/img/images/portfolio/Infra/target_user_img.png" alt="">
                </picture>
            </div>

            <div class="wrapper-infra">
                <div class="container-infra">
                    <div class="portfolio-description infra-restruction_desc wrapper">
                        <section>
                            <h2>RESTRUCTURING</h2>
                            <div class="horizontal-line"></div>
                            <p>
                                Competitors research showed us that the majority of cybersecurity companies
                                either overwhelm users with extremely detailed technical information or on the
                                contrary, show almost nothing except the name. While our
                                <strong>TU1</strong> might benefit from
                                technical details, for <strong>TU2</strong> they would be overwhelming and unnecessary.
                                The challenge was to find the right balance of the amount of information.
                            </p> <br>
                            <p>
                                Considering the different needs of both TUs we paid special attention to
                                clarifying the navigation.
                            </p>
                        </section>
                        <picture>
                            <source srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_1_mobile.png" media="(max-width: 320px)" />
                            <source srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_1_mobile-iphone.png" media="(max-width: 414px)" />

                            <img class="infra-restructing_1" src="/img/images/portfolio/Infra/RESTRUCTURING_1.png" alt="">
                        </picture>
                    </div>
                    <div class="wrap-restruction wrapper">
                        <picture>
                            <source srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_2_mobile.png" media="(max-width: 320px)" />
                            <source srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_2_mobile-iphone.png" media="(max-width: 414px)" />

                            <img class="infra-restruction_2" src="/img/images/portfolio/Infra/RESTRUCTURING_2.png" alt="">
                        </picture>
                        <picture>
                            <source srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_3_mobile.png" media="(max-width: 320px)" />
                            <source srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_3_mobile-iphone.png" media="(max-width: 414px)" />

                            <img class="infra-restruction_3" src="/img/images/portfolio/Infra/RESTRUCTURING_3.png" alt="">
                        </picture>
                    </div>
                    <div class="wrap-restruction wrapper">
                        <picture>
                            <source srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_4_mobile.png" media="(max-width: 320px)" />
                            <source srcset="/img/images/portfolio/Infra/Mobile/RESTRUCTURING_4_mobile-iphone.png" media="(max-width: 414px)" />

                            <img class="infra-restruction_4" src="/img/images/portfolio/Infra/RESTRUCTURING_4.png" alt="">
                        </picture>
                    </div>
                </div>
                <div class="wrapper wrapper-infra_feel">
                    <div class="portfolio-description infra-feel_desc">
                        <section>
                            <h2>LOOK and FEEL</h2>
                            <div class="horizontal-line"></div>
                            <p>
                                Cyber Security field is full of the websites that are using the abstract images
                                with circuit boards, binary code, and so on, from photo stocks. Working on INFRA’s
                                new look and feel, we wanted to avoid those cliches.
                            </p> <br>
                            <p>
                                <strong>INFRA opposes itself to the traditional ethical hacking </strong>
                                services by providing
                                automation instead of manual work. To emphasize the difference between INFRA and its
                                competitors and to stress on the create a feeling of <strong>automated, bugless, clean and almost robotic product
                                </strong>is that we have chosen the light minimalistic style with plenty of white space and metallic hues.
                            </p>
                        </section>
                        <picture>
                            <source srcset="/img/images/portfolio/Infra/Mobile/Look_and_feel_img_mobile.png" media="(max-width: 320px)" />
                            <source srcset="/img/images/portfolio/Infra/Mobile/Look_and_feel_img_mobile-iphone.png" media="(max-width: 414px)" />

                            <img class="infra-feel_img" src="/img/images/portfolio/Infra/Look_and_feel_img.png" alt="">
                        </picture>
                    </div>
                </div>
            </div>

            <picture>
                <source srcset="/img/images/portfolio/Infra/Mobile/line_divider_mobile.png" media="(max-width: 500px)" />
                <img class="infra-divider" src="/img/images/portfolio/Infra/line_divider.png" alt="">
            </picture>

            <div class="wrapper">
                <div class="portfolio-description">
                    <section>
                        <h2>RESULT</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            Website redesign became a good start of our collaboration with INFRA.
                            Being a rapidly developing company, INFRA requires plenty of design work,
                            starting from marketing materials and pitch decks, to INFRASCAN SaaS features
                            update and creating separated software products, that we happily provide.
                        </p>
                    </section>
                </div>
                <picture>
                    <source srcset="/img/images/portfolio/Infra/Mobile/Result_img_mobile.png" media="(max-width: 320px)" />
                    <source srcset="/img/images/portfolio/Infra/Mobile/Result_img_mobile-iphone.png" media="(max-width: 414px)" />

                    <img class="infra-result_img" src="/img/images/portfolio/Infra/Result_img .png" alt="">
                </picture>
            </div>
        </div>

        <img class="infra-footer-bg" src="/img/images/portfolio/Infra/bottom_dark_bg.png" alt="">
        <section class="more-projects infra-more_projects">
            <h2 class="main-title">More projects</h2>
            <div class="wrapper">
                <MoreProjects :projects="moreProjects"/>
            </div>
        </section>
        <Workshop />
    </main>
</template>

<script>
    import MoreProjects from "../partials/MoreProjects";
    import Workshop from "../Home/Workshop";

    export default {
        name: 'infra',
        components: {MoreProjects, Workshop},
        data() {
            return {
                moreProjects: [
                    {
                        image: "/img/images/portfolio/main/Jobtip.png",
                        image_hover: "/img/images/portfolio/main/Jobtip_clear.png",
                        title: "Jobtip",
                        url: "/portfolio/jobtip",
                        tags: [
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Landing Page",
                            },
                            {
                                name: "#Rebranding",
                            },
                            {
                                name: "#Illustrations",
                            }
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/health_wizz.png",
                        image_hover: "/img/images/portfolio/main/health_wizz_clear.png",
                        title: "Health Wizz",
                        url: "/portfolio/health",
                        tags: [
                            {
                                name: "#Webapp",
                            },
                            {
                                name: "#Blockchain",
                            },
                            {
                                name: "#Healthcare",
                            },
                            {
                                name: "#Design System",
                            },
                            {
                                name: "#SaaS",
                            },
                            {
                                name: "#Fintech",
                            }
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Human_capital.png",
                        image_hover: "/img/images/portfolio/main/Human_capital_clear.png",
                        title: "Human Capital",
                        url: "/portfolio/human-capital",
                        tags: [
                            {
                                name: "#Web Site",
                            },
                            {
                                name: "#UX Research",
                            },
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Recruitment",
                            },
                        ]
                    },
                ],
            }
        }
    }
</script>