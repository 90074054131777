<template>
    <!--SECTION CLIENTS-->
    <div class="section-review">
        <div class="wrapper">
            <h2 class="main-title">Clients talking about us</h2>
            <ul class="clients-list-slider row">
                <carousel :per-page="1"
                          :mouse-drag="true"
                          :navigationEnabled="true"
                          :centerMode="true"
                          :loop="true">
                    <slide v-for="(client, index) in CLIENTS"
                           v-bind:data="client.index"
                           v-bind:key="client.index">
                        <li>
                            <div class="item-client-slider">
                                <div class="clients-img">
                                    <img :src="client.image" alt="">
                                </div>
                                <div class="wrapper-description">
                                    <p>{{client.description}}</p>
                                    <div class="social-name">
                                        <a target="_blank" :href="client.link" class="link-client">
                                            <svg width="16px" height="16px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
                                                <title>LinkedIn</title>
                                                <desc>Created with Sketch.</desc>
                                                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="footer" transform="translate(-810.000000, -204.000000)" fill="#9C9EDE" fill-rule="nonzero">
                                                        <g id="Group">
                                                            <g id="icons" transform="translate(531.000000, 204.000000)">
                                                                <g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)">
                                                                    <path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </a>
                                        <div class="project-name">
                                            <span>
                                                <span>{{client.name}}</span>
                                                <a :href="client.project_link" target="_blank">{{client.project}}</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </slide>
                </carousel>
            </ul>
        </div>
    </div>
</template>

<script>
    import VueCarousel from 'vue-carousel';
    import { Carousel, Slide } from 'vue-carousel';
    import {CLIENTS} from '../constants/consts';
    let vm = {};

    export default {
        name: 'clients',

        components: {
            Carousel,
            Slide,
        },
        data(){
            return { CLIENTS }
        }
    }
</script>