import Vue from "vue";
import Router from "vue-router";

import Home from "./components/Home/Home";
import Ember from "./components/Portfolio/Ember";
import StressGuide from "./components/Portfolio/StressGuide";
import Alacris from "./components/Portfolio/Alacris";
import Jobtip from "./components/Portfolio/Jobtip";
import Health from "./components/Portfolio/Health";
import HumanCapital from "./components/Portfolio/HumanCapital";
import Infra from "./components/Portfolio/Infra";
import EarthRating from "./components/Portfolio/EarthRating";
import HowWeWork from "./components/Home/HowWeWork";
import ServicesPage from "./components/Services/ServicesPage";
import FreebiesPage from "./components/Freebies/FreebiesPage";
import Freebie from "./components/Freebies/Freebie";
import NotFoundPage from "./components/partials/NotFoundPage";
import GetEstimateForm from "./components/GetEstimateForm";
import Blog from "./components/Blog/Blog";
import Post from "./components/Blog/Post.vue";
import SuccessfullySendPage from "./components/SuccessfullySendPage";

Vue.use(Router);

export default new Router({
    mode: "history",
    scrollBehavior: function(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes: [
        {
            path: "/",
            name: "Home",
            component: Home
        },
        {
            path: "/how-we-work",
            name: "how-we-work",
            component: HowWeWork
        },
        {
            path: "/blog",
            name: "blog",
            component: Blog
        },
        {
            path: "/post/:name",
            name: "Post",
            component: Post
        },
        {
            path: "/get-estimate",
            name: "get-estimate",
            component: GetEstimateForm
        },
        {
            path: "/successfully-send",
            name: "successfully-send",
            component: SuccessfullySendPage
        },
        {
            path: "/services/:Surl",
            name: "services-page",
            component: ServicesPage
        },
        {
            path: "/freebies",
            name: "freebies-component",
            component: FreebiesPage,
            props: true
        },
        {
            path: "/freebies/:Furl",
            name: "freebie-component",
            component: Freebie,
            props: true
        },
        {
            path: "/portfolio/ember",
            name: "ember",
            component: Ember,
            props: true
        },
        {
            path: "/portfolio/stress-guide",
            name: "stress-guide",
            component: StressGuide,
            props: true
        },
        {
            path: "/portfolio/alacris",
            name: "alacris",
            component: Alacris,
            props: true
        },
        {
            path: "/portfolio/jobtip",
            name: "jobtip",
            component: Jobtip,
            props: true
        },
        {
            path: "/portfolio/health",
            name: "health",
            component: Health,
            props: true
        },
        {
            path: "/portfolio/human-capital",
            name: "human-capital",
            component: HumanCapital,
            props: true
        },
        {
            path: "/portfolio/infra",
            name: "infra",
            component: Infra,
            props: true
        },
        {
            path: "/portfolio/rating",
            name: "earth-rating",
            component: EarthRating,
            props: true
        },

        { path: "/not-found", name: "not-found", component: NotFoundPage },
        {
            path: "*",
            redirect: "/not-found"
        }
    ]
});
