<template>
    <main>
        <div class="current-portfolio">
            <picture>
                <source srcset="/img/images/portfolio/Jobtip/mobile/jobtip_header_img_mobile.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/Jobtip/mobile/header-iphone.png" media="(max-width: 414px)" />
                <source srcset="/img/images/portfolio/Jobtip/Header.png" media="(max-width: 1699px)" />
                <source srcset="/img/images/portfolio/Jobtip/jobtip_Header-full-hd.png" media="(min-width: 1700px)" />

                <img class="main-img_portfolio main-img_jobtip" src="/img/images/portfolio/Jobtip/jobtip_Header-full-hd.png" alt="">
            </picture>

            <section class="wrapper current-portfolio current-jobtip">
                <h1 class="current-portfolio_title">Jobtip</h1>
                <ul class="portfolio-list_tags">
                    <li>#Redesign</li>
                    <li>#Landing Page</li>
                    <li>#Rebranding</li>
                    <li>#Illustrations</li>
                </ul>
                <h2 class="current-portfolio_subtitle">
                   <em>Jobtip</em> is the platform that allows HR managers to boost their process.
                </h2>
                <p class="current-portfolio_description">
                    We were challenged to redesign the Swedish version of the Jobtip website by making it more appealing
                    to their target audience with a focus on the products which Jobtip provides.
                </p>
            </section>
            <picture>
                <source srcset="/img/images/portfolio/Jobtip/mobile/divider_1_mobile.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/Jobtip/mobile/employer_branding_jobtip_iphone.png" media="(max-width: 414px)" />

                <img class="jobtip-divider" src="/img/images/portfolio/Jobtip/divider_1.png" alt="">
            </picture>

            <div class="wrapper wrapper-jobtip">
                <div class="portfolio-description task-description">
                    <section>
                        <h2>Task</h2>
                        <div class="horizontal-line"></div>
                        <p>The goal of the redesign was to create a fully
                            <strong> new look and feel </strong>for
                            <a target="_blank" href="https://jobtip.com/">JobTip</a> .
                            The previous website didn’t highlight the company’s products and had a low conversion rate.
                            The design a lacke of consistency and had a very generic “WordPress” look.
                        </p>
                    </section>
                      <picture>
                        <source srcset="/img/images/portfolio/Jobtip/mobile/jobtip_tasks.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Jobtip/mobile/Imac2-iphone.png" media="(max-width: 414px)" />

                        <img class="jobtip-task_img" src="/img/images/portfolio/Jobtip/task.png" alt="">
                    </picture>
                </div>
            </div>

                     <picture>
                        <source srcset="/img/images/portfolio/Jobtip/mobile/divider_2_mobile.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Jobtip/mobile/job_application_iphone.png" media="(max-width: 414px)" />

                        <img class="jobtip-divider jobtip-divider-2" src="/img/images/portfolio/Jobtip/Divider_2.png" alt="">
                    </picture>

            <div class="wrapper wrapper-jobtip">
                <div class="portfolio-description procces-description">
                    <section>
                        <h2>Process</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            First, we learned that the reason for the <strong> low conversion </strong>possibly
                            was in a confusing representation of the products.
                        </p>
                        <p>
                            We started by clearing up the structure of the website and placing the right accents
                            in the right places. We wanted to convey the idea that the solutions JobTip is provided
                            are able to solve the problems of HR professionals automatically, so they can feel
                            more relaxed and stress-free. For this reason, and also to distinguish JobTip from
                            other HR websites, we decided to use bright colors and illustrations.
                        </p>
                        <p>
                            We created a couple of dozens of custom illustrations with the distinguished
                            style and color palette which suits their brand.
                        </p>
                    </section>
                    <picture>
                        <source srcset="/img/images/portfolio/Jobtip/mobile/process.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Jobtip/mobile/process-iphooone.png" media="(max-width: 414px)" />

                        <img class="jobtip-procces_img" src="/img/images/portfolio/Jobtip/Process.png" alt="">
                    </picture>
                </div>
            </div>
        </div>

        <section class="more-projects">
            <h2 class="main-title">More projects</h2>
            <div class="wrapper">
                <MoreProjects :projects="moreProjects"/>
            </div>
        </section>
        <Workshop />
    </main>
</template>

<script>
    import MoreProjects from "../partials/MoreProjects";
    import Workshop from "../Home/Workshop";

    export default {
        name: 'jobtip',
        components: {MoreProjects, Workshop},
        data() {
            return {
                moreProjects: [
                    {
                        image: "/img/images/portfolio/main/Human_capital.png",
                        image_hover: "/img/images/portfolio/main/Human_capital_clear.png",
                        title: "Human Capital",
                        url: "/portfolio/human-capital",
                        tags: [
                            {
                                name: "#Web Site",
                            },
                            {
                                name: "#UX Research",
                            },
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Recruitment",
                            },
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Infra.png",
                        image_hover: "/img/images/portfolio/main/Infra_clear.png",
                        title: "Infra",
                        url: "/portfolio/infra",
                        tags: [
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Rebranding",
                            },
                            {
                                name: "#Landing page",
                            },
                            {
                                name: "#Marketing design",
                            },
                            {
                                name: "#SaaS",
                            },
                            {
                                name: "#Cyber Security",
                            },
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Earth_rating.png",
                        image_hover: "/img/images/portfolio/main/Earth_rating_clear.png",
                        title: "Earth Rating",
                        url: "/portfolio/rating",
                        tags: [
                            {
                                name: "#Web App Prototyping",
                            },
                            {
                                name: "#Presentation",
                            },
                            {
                                name: "#Ecommerce",
                            },
                            {
                                name: "#Environment",
                            },
                        ]
                    },
                ],
            }
        }
    }
</script>