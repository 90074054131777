<template>
    <main>
        <div class="current-portfolio">
            <picture>
                <source srcset="/img/images/portfolio/ember/Mobile/Ember_header-iphone.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/ember/Mobile/Ember_header.png" media="(max-width: 414px)" />
                <source srcset="/img/images/portfolio/ember/Ember_header.png" media="(max-width: 1699px)" />
                <source srcset="/img/images/portfolio/ember/Ember_header-full-hd.png" media="(min-width: 1700px)" />
                <img class="main-img_portfolio main-img_ember" src="/img/images/portfolio/ember/Ember_header-full-hd.png" alt="">
            </picture>
            <section class="wrapper current-portfolio current-ember">
                <h1 class="current-portfolio_title">Embr Wave</h1>
                <ul class="portfolio-list_tags ember-list_tags">
                    <li>#Design Support</li>
                    <li>#Mobile App</li>
                    <li>#IoT</li>
                    <li>#Wearables</li>
                    <li>#User Research</li>
                </ul>
                <h2 class="current-portfolio_subtitle">
                    <em>Embr Wave </em>is a bracelet developed by MIT alumni, that improves the user’s
                    temperature comfort.
                </h2>
                <p class="current-portfolio_description">
                    We worked on updating and designing new features for the Embr Wave app that is coming
                    together with a bracelet and helps users to control it and see the visualized usage data.
                </p>
            </section>
            <picture>
                <source srcset="/img/images/portfolio/ember/Mobile/wave-pattern.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/ember/Mobile/wave-pattern-iphone.png" media="(max-width: 414px)" />

                <img class="ember-wave" src="/img/images/portfolio/ember/wave-pattern.png" alt="">
            </picture>
            <div class="wrapper">
                <div class="portfolio-description portfolio-story">
                    <section>
                        <h2>Story</h2>
                        <div class="horizontal-line"></div>
                        <p>Our collaboration with <a target="_blank" href="https://embrlabs.com/"> Embr Lab</a>, the company who produces Embr Wave started a few years
                            ago. When the startup was on the early stage of getting an investment, we when were helping with
                            creating some <strong>presentation materials.</strong>
                        </p> <br>
                        <p>Embr Wave became a successful startup. In 2018 we started working with Embr Wave team
                            again but in a different role. They needed to make some changes and modifications in
                            the <strong> app’s UI and UX.</strong> We were responsible for adding a new feature for the
                            heating/cooling modes customization, as well as multiple smaller modifications.
                        </p>
                    </section>
                    <picture>
                          <source srcset="/img/images/portfolio/ember/Mobile/Story.png" media="(max-width: 320px)" />
                          <source srcset="/img/images/portfolio/ember/Mobile/Story-iphone.png" media="(max-width: 414px)" />

                         <img class="ember-story_img" src="/img/images/portfolio/ember/Story.png" alt="">
                    </picture>
                </div>

                <div class="portfolio-description portfolio-process">
                    <section>
                        <h2>Process</h2>
                        <div class="horizontal-line"></div>
                        <p>Working on Embr Labs’ project we applied <strong>user testing</strong> and
                            <strong> user interview</strong> as the research method, besides the quantitative
                            analysis collected by Embr Labs.
                        </p> <br>
                        <p>
                            Equipped with the results we were receiving from the research:
                            <strong> adjusting our design</strong>
                            solutions for the user interface of even the UX flow to get perfect result.
                        </p> <br>
                        <p>
                            Besides that, one of our responsibilities was
                            <strong>communicating with the development team </strong> and help to overcome
                            the implementation challenges.
                        </p>
                    </section>
                    <picture>
                          <source srcset="/img/images/portfolio/ember/Mobile/Process_1.png" media="(max-width: 320px)" />
                          <source srcset="/img/images/portfolio/ember/Mobile/Rectangle-iphone.png" media="(max-width: 414px)" />

                         <img class="process-img" src="/img/images/portfolio/ember/Process.png" alt="">
                    </picture>
                </div>
                    <picture>
                          <source srcset="/img/images/portfolio/ember/Mobile/Process_2.png" media="(max-width: 320px)" />
                          <source srcset="/img/images/portfolio/ember/Mobile/screens_embr-iphone.png" media="(max-width: 414px)" />

                          <img class="portfolio-bottom_img" src="/img/images/portfolio/ember/process_2.png" alt="">
                    </picture>
            </div>
        </div>

        <img class="ember-projects_bg" src="/img/images/portfolio/ember/bottom_black_bg.png" alt="">
        <section class="more-projects ember-more_projects">
            <h2 class="main-title">More projects</h2>
            <div class="wrapper">
                <MoreProjects :projects="moreProjects"/>
            </div>
        </section>
        <Workshop />
    </main>
</template>

<script>
    import MoreProjects from "../partials/MoreProjects";
    import Workshop from "../Home/Workshop";

    export default {
        name: 'ember',
        components: {MoreProjects,Workshop},
        data() {
            return {
                moreProjects: [
                    {
                        image: "/img/images/portfolio/main/Stressguide.png",
                        image_hover: "/img/images/portfolio/main/Stressguide_clear.png",
                        title: " Stress Guide",
                        url: "/portfolio/stress-guide",
                        tags: [
                            {
                                name: "#Mobile App",
                            },
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#User Research",
                            },
                            {
                                name: "#Healthcare",
                            },
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Alacris.png",
                        image_hover: "/img/images/portfolio/main/Alacris_clear.png",
                        title: " Alacris",
                        url: "/portfolio/alacris",
                        tags: [
                            {
                                name: "#Design Support",
                            },
                            {
                                name: "#Demo Prototype",
                            },
                            {
                                name: "#Fintech",
                            },
                            {
                                name: "#Blockchain",
                            },
                            {
                                name: "#Landing Page",
                            },
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Jobtip.png",
                        image_hover: "/img/images/portfolio/main/Jobtip_clear.png",
                        title: "Jobtip",
                        url: "/portfolio/jobtip",
                        tags: [
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Landing Page",
                            },
                            {
                                name: "#Rebranding",
                            },
                            {
                                name: "#Illustrations",
                            }
                        ]
                    },
                ],
            }
        }
    }
</script>