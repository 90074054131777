<template>
  <div>
    <div class="wrap-get-estimate">
      <div class="wrapper">
        <section class="get-free-estimate">
          <h1 class="title-get-free-estimate">Get free estimate</h1>
          <h2 class="form-title">fill the form</h2>
          <form action="#" class="form-wrapper">
            <div class="input-textarea-wrapper">
              <div class="input-wrapper">
                <p>
                  <label v-bind:style="styles.name.label" for="name">Your name</label>
                  <input
                    v-bind:style="styles.name.input"
                    type="text"
                    id="name"
                    v-model="mailData.name"
                    v-on:click="delErrors('name')"
                  />
                </p>
                <p>
                  <label v-bind:style="styles.company.label" for="company">Company name</label>
                  <input
                    v-bind:style="styles.company.input"
                    type="text"
                    id="company"
                    v-model="mailData.company"
                    v-on:click="delErrors('company')"
                  />
                </p>
                <p>
                  <label v-bind:style="styles.email.label" for="email">Email</label>
                  <input
                    v-bind:style="styles.email.input"
                    type="text"
                    id="email"
                    v-model="mailData.email"
                    v-on:click="delErrors('email')"
                  />
                </p>
              </div>
              <p class="textarea-wrapper">
                <label v-bind:style="styles.text.label" for="email" v-html="lables.text"></label>
                <textarea
                  v-bind:style="styles.text.input"
                  name
                  id
                  cols="35"
                  rows="24"
                  v-model="mailData.text"
                  v-on:click="delErrors('text')"
                ></textarea>
              </p>
              <p></p>
            </div>
            <button type="submit" v-on:click="sendMail()" class="submit-btn">Send</button>
          </form>
          <p class="or">OR</p>
          <a
            href="https://calendly.com/bozkagames/30min?month=2019-09"
            class="schedule-meeting-link"
          >Schedule meeting</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { requestGet, requestPost } from "../services/axios.js";
let vm = {};
export default {
  name: "get-estimate",
  components: {},
  data() {
    return {
      mailData: {
        name: "",
        company: "",
        text: "",
        email: ""
      },
      errors: [],
      lables: {
        text: `Tell about your project 
              <br />(problems, ideas, timeline, budget)`
      },
      styles: {
        name: {
          input: "",
          label: ""
        },
        company: {
          input: "",
          label: ""
        },
        text: {
          input: "",
          label: ""
        },
        email: {
          input: "",
          label: ""
        }
      }
    };
  },
  beforeCreate() {
    vm = this;
  },
  methods: {
    delErrors: field => {
      if (field == "text") {
        vm.lables.text = "Tell about your project ";
      }
      vm.styles[field]["label"] =
        "transform: translateY(-25px); font-size: 10px;";
      vm.styles[field]["input"] = "";
    },
    validEmail: email => {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    hasErrors: () => {
      let gotError = false;
      Object.keys(vm.mailData).forEach(item => {
        if (vm.mailData[item] == "") {
          gotError = true;
          vm.styles[item]["label"] += "color: #FF7F5F;";
          vm.styles[item]["input"] += "border-bottom-color: #FF7F5F;";
        } else if (item == "email" && !vm.validEmail(vm.mailData[item])) {
          gotError = true;
          vm.styles[item]["label"] += "color: #FF7F5F;";
          vm.styles[item]["input"] += "border-bottom-color: #FF7F5F;";
        }
      });

      return gotError;
    },
    sendMail: () => {
      if (!vm.hasErrors()) {
        requestPost("/contact-us", vm.mailData).then( res => {
          if (res.status) {
            vm.$router.push("/successfully-send");
          } else {
            console.log('Email send failed, error',res.error);
            console.log('resend email');
            vm.sendMail();
          }
        });
      }
    }
  }
};
</script>