<template>
    <main>
        <div class="current-portfolio current-earth_rating">
            <picture>
                <source srcset="/img/images/portfolio/Earth_rating/Mobile/Header_img.png" media="(max-width: 320px)" />
                <source srcset="/img/images/portfolio/Earth_rating/Mobile/Group_7-iphone.png" media="(max-width: 414px)" />
                <source srcset="/img/images/portfolio/Earth_rating/header_img.png" media="(max-width: 1699px)" />
                <source srcset="/img/images/portfolio/Earth_rating/ER_header_full_hd.png" media="(min-width: 1700px)" />

                <img class="main-img_portfolio" src="/img/images/portfolio/Earth_rating/ER_header_full_hd.png" alt="">
            </picture>
            <section class="wrapper current-portfolio current-rating-portfolio">
                <h1 class="current-portfolio_title">Earth Ratings</h1>
                <ul class="portfolio-list_tags">
                    <li>#Web App Prototyping</li>
                    <li>#Presentation</li>
                    <li>#Ecommerce</li>
                    <li>#Environment</li>
                </ul>
                <h2 class="current-portfolio_subtitle">
                    <em>Earth Ratings </em>helps people to consume consciously considering the environmental
                    impact of the products.
                </h2>
                <p class="current-portfolio_description">
                    For Earth Ratings we created the web app prototype for MVP having very rough wireframes as basic.
                </p>
            </section>
            <picture>
                <source srcset="/img/images/portfolio/Earth_rating/Mobile/bg_img_1.png" media="(max-width: 500px)" />

                <img class="er-main-bg_img" src="/img/images/portfolio/Earth_rating/bg_1.png" alt="">
            </picture>
            <div class="wrapper wrapper-earth_rating">

                <div class="portfolio-description rating-target_description">
                    <section>
                        <h2>Target audience</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            We defined our target audience as high middle-class people who can afford to be
                            conscious consumers. Most likely they are millennials who are concerned about the
                            environmental and social impact of the manufacturing goods.
                        </p>
                    </section>
                    <picture>
                        <source srcset="/img/images/portfolio/Earth_rating/Mobile/target.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Earth_rating/Mobile/Group_8-iphone.png" media="(max-width: 414px)" />

                        <img class="rating-audience_img" src="/img/images/portfolio/Earth_rating/target_audience.png" alt="">
                    </picture>
                </div>

                <div class="portfolio-description rating-challenge_description">
                    <section>
                        <h2>Challenge</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            Besides the challenge of creating smooth and trustworthy UX for our target users, we
                            faced quite a lot of difficulties with the architecture and UI.
                            The most interesting challenges were:
                        </p> <br>
                        <p>
                            1. Visualize the impact percentage as clear as possible, without losing the details.
                        </p> <br>
                        <p>
                            2. Design a temporary, yet, viable solution for the purchase flow
                            that will partially happen outside of the app.
                        </p>
                    </section>
                       <picture>
                            <source srcset="/img/images/portfolio/Earth_rating/Mobile/Challenge.png" media="(max-width: 320px)" />
                            <source srcset="/img/images/portfolio/Earth_rating/Mobile/Group_10-iphone.png" media="(max-width: 414px)" />

                            <img class="rating-challenge-img" src="/img/images/portfolio/Earth_rating/Challenge.png" alt="">
                        </picture>
                </div>

                <div class="portfolio-description rating-process_description">
                    <section>
                        <h2>Process</h2>
                        <div class="horizontal-line"></div>
                        <p>
                            While designing the prototype for
                            <a target="_blank" href="https://earthratings.com/"> Earth Ratings </a>
                            we were running quick internal
                            user tests that mostly aimed to check the correctness of the flows and UI.
                            After the demo was done, Earth Ratings run the user testing with target users.
                            We used its insights to improve the existing prototype even more.
                        </p>
                    </section>
                    <picture>
                        <source srcset="/img/images/portfolio/Earth_rating/Mobile/Process.png" media="(max-width: 320px)" />
                        <source srcset="/img/images/portfolio/Earth_rating/Mobile/Group_9-iphone.png" media="(max-width: 414px)" />

                        <img class="rating-process-img" src="/img/images/portfolio/Earth_rating/process.png" alt="">
                    </picture>
                </div>
            </div>
        </div>

        <section class="more-projects">
            <h2 class="main-title">More projects</h2>
            <div class="wrapper">
                <MoreProjects :projects="moreProjects"/>
            </div>
        </section>
        <Workshop />
    </main>
</template>

<script>

    import MoreProjects from "../partials/MoreProjects";
    import Workshop from "../Home/Workshop";

    export default {
        name: 'earth-rating',

        components: {MoreProjects, Workshop},
        data() {
            return {
                moreProjects: [
                    {
                        image: "/img/images/portfolio/main/health_wizz.png",
                        image_hover: "/img/images/portfolio/main/health_wizz_clear.png",
                        title: "Health Wizz",
                        url: "/portfolio/health",
                        tags: [
                            {
                                name: "#Webapp",
                            },
                            {
                                name: "#Blockchain",
                            },
                            {
                                name: "#Healthcare",
                            },
                            {
                                name: "#Design System",
                            },
                            {
                                name: "#SaaS",
                            },
                            {
                                name: "#Fintech",
                            }
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Human_capital.png",
                        image_hover: "/img/images/portfolio/main/Human_capital_clear.png",
                        title: "Human Capital",
                        url: "/portfolio/human-capital",
                        tags: [
                            {
                                name: "#Web Site",
                            },
                            {
                                name: "#UX Research",
                            },
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Recruitment",
                            },
                        ]
                    },
                    {
                        image: "/img/images/portfolio/main/Infra.png",
                        image_hover: "/img/images/portfolio/main/Infra_clear.png",
                        title: "Infra",
                        url: "/portfolio/infra",
                        tags: [
                            {
                                name: "#Redesign",
                            },
                            {
                                name: "#Rebranding",
                            },
                            {
                                name: "#Landing page",
                            },
                            {
                                name: "#Marketing design",
                            },
                            {
                                name: "#SaaS",
                            },
                            {
                                name: "#Cyber Security",
                            },
                        ]
                    },
                ],
            }
        }
    }
</script>