<template>
  <header id="header" v-scroll="handleScroll">
    <nav class="main-nav wrapper row">
      <ul id="nav" class="list-main-nav row col-md-10 col-sm-10">
        <li>
          <div class="wrap-lines">
            <span class="line line-long"></span>
            <span class="line line-short"></span>
          </div>
          <span class="item-down">Services</span>
          <ul class="list-dropdown">
            <li v-for="service in SERVICES" :key="service.title">
              <a v-on:click="goToServices(service.url)">{{service.title}}</a>
              <span class="vertical-line"></span>
            </li>
          </ul>
        </li>
        <li v-for="item in HeaderMenu" :class="!item.mobile  ? 'logo-item':'' ">
          <div v-if="item.mobile" class="wrap-lines">
            <span class="line line-long"></span>
            <span class="line line-short"></span>
          </div>
          <router-link :to="item.href" :class="!item.mobile ? 'logo' : '' " v-html="item.name"></router-link>
        </li>
      </ul>
      <ListSocial class="list-social row col-md-2 col-sm-2" />
      <div class="wrap-mobile-menu">
        <div id="nav-icon4" v-on:click="activeBurger = !activeBurger" :class="[activeBurger ? 'open' : '']" >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <router-link to="/" class="logo">
          <img src="/img/images/logo/logo.svg" alt />
        </router-link>
        <div class="wrap-mobile-list-menu" :class="[activeBurger ? 'active-mobile-list-menu' : '']">
          <ul class="mobile-list-menu">
            <li v-on:click="activeBurger = false">
              <router-link to="/" class="logo">
                <img src="/img/images/logo/logo.svg" alt />
              </router-link>
            </li>
            <li>
              <span class="mobile-item-down" v-on:click="dropDown($event)">Services</span>
              <ul class="mobile-list-dropdown">
                <li v-for="service in SERVICES" v-on:click="activeBurger = false" :key="service.title" >
                  <a v-on:click="goToServices(service.url)">{{service.title}}</a>
                </li>
              </ul>
            </li>
            <li v-for="item in HeaderMenu" v-if="item.mobile" v-on:click="activeBurger = false">
              <router-link :to="item.href">{{item.name}}</router-link>
            </li>
          </ul>
          <ListSocial class="list-social list-social-mobile row col-xs-7" />
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import Vue from "vue";
import ListSocial from "./ListSocial";
import { SERVICES , HeaderMenu } from "../constants/consts";

let vm = {};

Vue.directive("scroll", {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  }
});

export default {
  name: "app-header",
  components: { ListSocial },

  data() {
    return {
      activeBurger: false,
      SERVICES,
      HeaderMenu,
    };
  },
  mounted() {
    vm.headerPortfolio();
  },
  beforeCreate() {
    vm = this;
  },

  methods: {
    goToServices: serviceUrl => {
      vm.$router.push({ name: "services-page", params: { Surl: serviceUrl } });
    },

    dropDown: event => {
      let panel = event.currentTarget.nextElementSibling;
      if (panel.style.height) {
        panel.style.height = null;
        panel.classList.remove("active-list-dropdown");
      } else {
        panel.style.height = panel.scrollHeight + "px";
        panel.classList.add("active-list-dropdown");
      }
    },

    headerPortfolio: () => {
      let currentUrl = window.location.pathname.slice(1, 10);
      let currentWork = window.location.pathname.slice(1);
      if (
        currentWork === "how-we-work" ||
        currentUrl === "portfolio" ||
        currentWork.includes("post/")
      ) {
        let header = document.getElementById("header");
        header.style.backgroundColor = "#25273E";
      }
    },

    handleScroll: (evt, el) => {
      if (window.scrollY > 230) {
        el.setAttribute("style", "background-color: #25273E;");
      } else {
        let currentUrl = window.location.pathname.slice(1, 10);
        let currentWork = window.location.pathname.slice(1);
        if (
          currentUrl === "portfolio" ||
          currentWork === "how-we-work" ||
          currentUrl === "services/" ||
          currentUrl === "freebies" ||
          currentWork === "blog" ||
          currentWork === "get-estimate" ||
          currentWork.includes("post/")
        ) {
          el.setAttribute("style", "background-color: #25273E;");
        } else {
          el.setAttribute("style", "background-color: transparent;");
        }
      }
    }
  }
};
</script>